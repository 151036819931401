import { useState, useCallback, useEffect, useMemo } from 'react'
import Select, { components } from 'react-select'
import { Button, Input } from 'reactstrap'
import DatePicker from "react-datepicker"
import AppConstants from '../../api/constants/AppConstants'
import "react-datepicker/dist/react-datepicker.css"
import CurrencyInputField from '../common/CurrencyInputField'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import DeleteIcon from '../common/icons/DeleteIcon'
import VehicleStateIcon from '../../pages/myLot/VehicleStateIcon'
import pick from 'lodash/pick'
import DateFunctions from '../../api/constants/DateFunctions'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'
import SessionStore from '../../api/stores/SessionStore'
import Spinner from '../common/Spinner'

const removeVehicleEnabled = false

const VehicleTransactionDataForm = ({ vehicle, onCancel, onDelete, onUpdate, clientID }) => {
  const isNewRecord = !vehicle.lot_vehicle
  const originallyOnLot = isNewRecord ? false : vehicle.lot_vehicle.state !== null

  const [lotVehicle, setLotVehicle] = useState(isNewRecord ? {} : vehicle.lot_vehicle)
  const [validationErrors, setValidationErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didAttemptSubmit, setDidAttemptSubmit] = useState(false)

  const initialAlertRetailProfitThreshold = isNewRecord ? null : vehicle.lot_vehicle.alert_retail_profit_threshold
  const [inputAlertRetailProfitThreshold, setInputAlertRetailProfitThreshold] = useState(initialAlertRetailProfitThreshold ?? undefined)

  const [{ alertSettings: { alertsRetailProfitThreshold: alertSettingsRetailProfitThreshold} }] = useMyLotSettings()

  const isValid = useMemo(() => {
    return !Object.keys(validationErrors).length
  }, [validationErrors])

  const onDataChange = useCallback(() => {
    setIsSubmitting(false)
    onCancel()
    onUpdate?.()
  }, [onCancel, onUpdate])

  const onLotVehicleDeleted = useCallback(() => {
    onDelete()
  }, [onCancel])

  useEffect(() => {
    const lotVehicleUpdatedEv = `lot_vehicle_updated_${clientID}`
    const lotVehicleDeletedEv = `lot_vehicle_deleted_${clientID}`
    ValuationStore.on(lotVehicleUpdatedEv, onDataChange)
    ValuationStore.on(lotVehicleDeletedEv, onLotVehicleDeleted)

    return () => {
      ValuationStore.removeListener(lotVehicleUpdatedEv, onDataChange)
      ValuationStore.removeListener(lotVehicleDeletedEv, onLotVehicleDeleted)
    }
  }, [onDataChange, onLotVehicleDeleted, clientID, vehicle])

  const onSubmit = useCallback(e => {
    e.preventDefault()
    if (!isValid) {
      setDidAttemptSubmit(true)
      return
    }

    setIsSubmitting(true)
    let serverParams = {
      ...pick(lotVehicle, [
        'state',
        'price_cents',
        'purchase_price_cents',
        'sale_price_cents',
        'purchased_at',
        'purchase_type',
        'sold_at',
        'sale_type',
        'recon_cost_cents',
        'transportation_cost_cents',
        'auction_fees_cost_cents',
        'misc_fees_cost_cents',
        'stock_number',
      ]),
      uuid: vehicle.uuid
    }

    if (serverParams.state !== 'sold') {
      serverParams.sale_price_cents = null
      serverParams.sold_at = null
      serverParams.sale_type = null
    }

    serverParams.alert_retail_profit_threshold = (function() {
      if (inputAlertRetailProfitThreshold !== undefined && inputAlertRetailProfitThreshold !== alertSettingsRetailProfitThreshold) {
        return inputAlertRetailProfitThreshold
      } else if (inputAlertRetailProfitThreshold != null && initialAlertRetailProfitThreshold != null) {
        return inputAlertRetailProfitThreshold
      } else if (alertSettingsRetailProfitThreshold == null) {
        return inputAlertRetailProfitThreshold ?? null
      } else {
        return initialAlertRetailProfitThreshold
      }
    })();
    ValuationActionCreators.updateLotVehicle(serverParams, clientID)
  }, [lotVehicle, vehicle.uuid, clientID, isValid, inputAlertRetailProfitThreshold, alertSettingsRetailProfitThreshold, initialAlertRetailProfitThreshold])

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: '2px',
      background: '#fff',
      borderColor: '#ced4da',
      minHeight: '38px',
      height: '38px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      minHeight: '38px !important',
      maxHeight: '38px !important',
      marginTop: '-4px !important',
      paddingTop: '0px !important',
    }),

    input: (provided) => ({
      ...provided,
      margin: '0px',
      minHeight: '38px',
      height: '38px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '36px',
      minHeight: '36px',
      maxHeight: '36px',
    }),
    option: (provided) => ({
      ...provided,
      borderBottom: '1px solid #aaa',
      padding: '6px'
    }),
  }

  const vehicleStatusStyles = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      ...customStyles.control(provided, state),
      borderColor: '#888',
    }),
  }

  const setCurrencyValue = (value, lotVehicleParamName) => {
    const formattedCurrencyCents = value.length ? value * 100 : null
    setLotVehicle({
      ...lotVehicle,
      [lotVehicleParamName]: formattedCurrencyCents
    })
  }

  const setDateValue = (value, lotVehicleParamName) => {
    const formatted = value ? DateFunctions.iSODateToDateOnlyString(value) : null
    setLotVehicle({
      ...lotVehicle,
      [lotVehicleParamName]: formatted
    })
  }

  const dateInputValue = (date) => {
    return date ? DateFunctions.dateOnlyStringToJSDate(date) : null
  }

  const currencyInputValue = (cents) => {
    // Preserve null values
    return cents == null ? null : cents / 100
  }

  useEffect(() => {
    // field validation
    const v = lotVehicle
    const e = {}

    if (v.state == 'sold') {
      if (!v.sold_at) {
        e.sold_at = 'Sold date is required'
      }

      if (!v.purchased_at) {
        e.sold_at = 'Purchase date is required'
      }

      if (!v.sale_price_cents) {
        e.sale_price_cents = 'Sale price is required'
      }

      if (!v.purchase_price_cents) {
        e.purchase_price_cents = 'Purchase price is required'
      }

      if (v.purchased_at && v.sold_at && v.purchased_at > v.sold_at) {
        e.sold_at = 'Sale date must be on or after purchase date'
      }
    } else {
      if (!v.purchased_at) {
        e.purchased_at = 'Purchase date is required'
      }

      if (!v.purchase_price_cents) {
        e.purchase_price_cents = 'Purchase price is required'
      }
    }

    setValidationErrors(e)
  }, [lotVehicle])

  const CustomOption = (props) => {
    if (props.isDisabled) {
      return null
    } else {
      return (
        <components.Option {...props} >
          <div style={{display: 'flex', flexDirection: 'row'}} >
            <div className='my-lot-select-state-icon'>
              <VehicleStateIcon vehicleState={props.value} />
            </div>
            <div>{props.label}</div>
          </div>
        </components.Option>
      )
    }
  }

  const CustomControl = (props) => {
    if (props.isDisabled) {
      return null
    } else {
      return (
        <components.Control {...props}>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}} >
            {
              props.getValue().length > 0 &&
              <div style={{}}><VehicleStateIcon className='my-lot-vehicle-state-control-icon' vehicleState={props.getValue()[0].value} /></div>
            }
            {props.children}
          </div>
        </components.Control>
      )
    }
  }

  return (
    <div className='my-lot-transaction-form'>

      {
        vehicle.year && vehicle.make && vehicle.model &&
        <div style={{backgroundColor: '#111', padding: '10px', borderTopRightRadius: '3px', borderTopLeftRadius: '3px'}}>
          <div style={{color: '#fff'}}>{vehicle.year} {vehicle.make} {vehicle.model}</div>
        </div>
      }

      <form onSubmit={onSubmit}>
        <section>

          <div style={{width: '280px'}}>
            <div className='input-label'>Vehicle Status</div>
            <Select
              styles={vehicleStatusStyles}
              name="vehicleStatus"
              value={lotVehicle.state ? {value: lotVehicle.state, label: AppConstants.myLotStateNames[lotVehicle.state]} : null}
              isSearchable={false}
              components={{
                Option: CustomOption,
                Control: CustomControl,
              }}
              onChange={(selected) => {
                setLotVehicle({
                  ...lotVehicle,
                  state: selected.value
                })
              }}
              options={
                AppConstants.myLotStateValues.map((state, i) => (
                  {
                    value: state,
                    label: AppConstants.myLotStateNames[state]
                  }
                ))
              }
            />
          </div>


          <div style={{width: '100%', backgroundColor: '#111', height: '1px', marginTop: '20px'}}></div>


          <div className='split-row' style={{marginTop: '20px'}}>
            <div>
              <div className='input-label'>Listed price</div>
              <CurrencyInputField unmask value={currencyInputValue(lotVehicle.price_cents)} onChange={(value) => setCurrencyValue(value, 'price_cents')} style={{margin: 0, width: '240px' }} />
            </div>

            <div>
              <div className='input-label'>Stock #</div>
              <Input
                type="text"
                className="field"
                style={{marginBottom: 0, width: '240px'}}
                onChange={(e) => setLotVehicle(prev => ({ ...prev, stock_number: e.target.value || null }))}
                value={lotVehicle.stock_number || ''}
                placeholder="Stock #"
                maxLength={50}
                onFocus={(e) => {e.target.select()}}
              />
            </div>
          </div>

          {
            SessionStore.user?.rooftop?.lot_inventory_status === 'read_write' &&
            <div style={{color: 'red', fontSize: '0.9rem', marginTop: '5px'}}>You have price changes enabled, so any change you make to the listed price here will be published back through your system of record.</div>
          }

        </section>

        <section style={{marginTop: '40px'}}>
          <h2>Purchase Details</h2>

          <div className='split-row' style={{alignItems: 'flex-start', columnGap: '20px'}}>
            <div style={{width: '25%'}} className='date-picker'>
              <div className='input-label'>Purchase date *</div>
              <DatePicker selected={dateInputValue(lotVehicle.purchased_at)} onChange={date => setDateValue(date, 'purchased_at')} />
            </div>

            <div style={{width: '25%'}}>
              <div className='input-label'>Purchase amount *</div>
              <CurrencyInputField unmask value={currencyInputValue(lotVehicle.purchase_price_cents)} onChange={(value) => setCurrencyValue(value, 'purchase_price_cents')} style={{margin: 0 }} />
            </div>

            <div style={{width: '50%'}}>
              <div className='input-label'>Purchase method</div>
              <Select
                styles={customStyles}
                name="purchaseType"
                value={lotVehicle.purchase_type ? {value: lotVehicle.purchase_type, label: AppConstants.myLotPurchaseTypes[lotVehicle.purchase_type]} : null}
                isSearchable={false}
                onChange={(selected) => {
                  setLotVehicle({
                    ...lotVehicle,
                    purchase_type: selected.value
                  })
                }}
                options={
                  Object.keys(AppConstants.myLotPurchaseTypes).map((purchaseType, i) => (
                    {
                      value: purchaseType,
                      label: AppConstants.myLotPurchaseTypes[purchaseType]
                    }
                  ))
                }
              />
            </div>

          </div>
        </section>


        <section>
          <h2>Costs</h2>
          <div className='split-row' style={{alignItems: 'flex-start', columnGap: '20px'}}>
            <div style={{width: '25%', flexBasis: '25%'}}>
              <div className='input-label'>Auction fees</div>
              <CurrencyInputField unmask value={currencyInputValue(lotVehicle.auction_fees_cost_cents)} onChange={(value) => setCurrencyValue(value, 'auction_fees_cost_cents')} style={{margin: 0}} />
            </div>

            <div style={{width: '25%', flexBasis: '25%'}}>
              <div className='input-label'>Recon costs</div>
                <CurrencyInputField unmask value={currencyInputValue(lotVehicle.recon_cost_cents)} onChange={(value) => setCurrencyValue(value, 'recon_cost_cents')} style={{margin: 0}} />
            </div>

            <div style={{width: '25%', flexBasis: '25%'}}>
              <div className='input-label'>Transport costs</div>
              <CurrencyInputField unmask value={currencyInputValue(lotVehicle.transportation_cost_cents)} onChange={(value) => setCurrencyValue(value, 'transportation_cost_cents')} style={{margin: 0}} />
            </div>

            <div style={{width: '25%', flexBasis: '25%'}}>
              <div className='input-label'>Misc costs</div>
              <CurrencyInputField unmask value={currencyInputValue(lotVehicle.misc_fees_cost_cents)} onChange={(value) => setCurrencyValue(value, 'misc_fees_cost_cents')} style={{margin: 0}} />
            </div>
          </div>
        </section>


        {
          lotVehicle.state === 'sold' &&
          <section>
            <h2>Sale Details</h2>

            <div className='split-row' style={{alignItems: 'flex-start', columnGap: '20px'}}>
              <div style={{width: '25%'}} className='date-picker'>
                <div className='input-label'>Sale date *</div>
                <DatePicker selected={dateInputValue(lotVehicle.sold_at)} onChange={date => setDateValue(date, 'sold_at')} />
              </div>

              <div style={{width: '25%'}}>
                <div className='input-label'>Sale amount *</div>
                <CurrencyInputField unmask value={currencyInputValue(lotVehicle.sale_price_cents)} onChange={(value) => setCurrencyValue(value, 'sale_price_cents')} style={{margin: 0}} />
              </div>


              <div style={{width: '50%'}}>
                <div className='input-label'>Sale method</div>
                <Select
                  styles={customStyles}
                  name="saleType"
                  value={lotVehicle.sale_type ? {value: lotVehicle.sale_type, label: AppConstants.myLotSaleTypes[lotVehicle.sale_type]} : null}
                  isSearchable={false}
                  onChange={(selected) => {
                    setLotVehicle({
                      ...lotVehicle,
                      sale_type: selected.value
                    })
                  }}
                  options={
                    Object.keys(AppConstants.myLotSaleTypes).map((saleType, i) => (
                      {
                        value: saleType,
                        label: AppConstants.myLotSaleTypes[saleType]
                      }
                    ))
                  }
                />
              </div>
            </div>
          </section>
        }

        {
          lotVehicle.state !== 'sold' &&
          <section>
            <h2>Retail profit threshold</h2>
            <div>
              <div className='secondary-text'>You will be alerted if this vehicle's potential retail profit falls below this value.</div>
              <CurrencyInputField
                unmask
                value={inputAlertRetailProfitThreshold === undefined ? alertSettingsRetailProfitThreshold : inputAlertRetailProfitThreshold}
                onChange={v => setInputAlertRetailProfitThreshold(v.length ? parseInt(v) : null)}
                style={{ margin: 0, width: 240 }}
              />
            </div>
          </section>
        }

        {
          !isValid && didAttemptSubmit &&
          <div style={{display: 'flex', flexWrap: 'wrap', columnGap: '10px', rowGap: '5px', color: 'red', marginBottom: '-20px'}}>
            {
              Object.values(validationErrors).map((message, i) => (
                <div key={i} style={{border: '1px solid red', padding: '2px 5px', borderRadius: '4px'}}>{message}</div>
              ))
            }
          </div>
        }

        <div className='split-row' style={{marginTop: '60px'}}>
          {
            isSubmitting ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '20px'}}>
              <Spinner width={25} height={25} style={{width: '25px', height: '25px', margin: '0px', marginRight: '5px'}} />
            </div>
            :
            <>
              {
                removeVehicleEnabled && originallyOnLot && onDelete ?
                <a href="#delete" onClick={(e) => {
                  e.preventDefault()
                  if (confirm('If this vehicle has been sold, use the status of "Sold" instead of removing it from the lot. Are you sure you want to remove this vehicle from the lot?')) {
                    ValuationActionCreators.deleteLotVehicle(lotVehicle.uuid, clientID)
                  }
                }}><DeleteIcon /> Remove from lot</a> : <div></div>
              }

              <div style={{display: 'flex', justifyContent: 'flex-end', columnGap: '10px'}}>
                <Button color='secondary' onClick={onCancel}>Cancel</Button>
                <Button type='submit' color='primary' disabled={!isValid && didAttemptSubmit}>Save</Button>
              </div>
            </>
          }
          
        </div>
      </form>
    </div>
  )
}

export default VehicleTransactionDataForm
