import { useState } from "react"
import Scorecard from '../retail/Scorecard'
import AppConstants from '../../api/constants/AppConstants'
import SessionStore from "../../api/stores/SessionStore"
import ExpandIcon from "../common/icons/ExpandIcon"
import ContractIcon from "../common/icons/ContractIcon"
import PreviewMessage from "../myLot/PreviewMessage"

const ValuationScorecard = ({ valuation }) => {
  const [isCollapsed, setIsCollapsed] = useState(SessionStore.collapsedLotSenseScorecardValuations === true)

  return (
    <section className="card valuation-section" id='my_lot'>
      <div className="split-row" style={{justifyContent: 'space-between'}}>
        <h2>{AppConstants.providerNames['regional_performance']}</h2>
        <a href="#toggle" onClick={(e) => {
          e.preventDefault()
          setIsCollapsed(!isCollapsed)
          SessionStore.setCollapsedLotSenseScorecardValuations(!isCollapsed)
        }}>
          {
            isCollapsed ?
            <ExpandIcon width={28} height={28} color='#aaa' /> : <ContractIcon width={28} height={28} color='#aaa' />
          }
        </a>
      </div>
      
      {
        !isCollapsed &&
        <>
          <h3>{[valuation.year, valuation.make, valuation.model, valuation.series].join(' ')}</h3>
          {
            SessionStore.isPreviewingLotSense() &&
            <div style={{marginTop: '10px'}}>
              <PreviewMessage />
            </div>
          }

          <Scorecard
            valuation={valuation}
            className='my-lot-scorecard-compact'
          />
        </>
      }
    </section>
  )
}

export default ValuationScorecard
