import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import platformFunctions from '../../platformFunctions'
import StripeForm from './StripeForm'

const stripePromise = loadStripe(platformFunctions.isProduction() ? 'pk_live_XAlgBkGs6TPVfbQm4uUayhVT' : 'pk_test_ZWnzUXq9EnnYjKQo8zk8DIRh')
const stripeOptions = {
  mode: 'setup',
  currency: 'usd',
  paymentMethodCreation: 'manual',
  // Fully customizable with appearance API: https://docs.stripe.com/js/elements_object
  // appearance: {theme: 'stripe'}
}

export default class PaymentForm extends React.Component {

  render() {
    return(
      <div className="account-payment-form">
        {
          this.props.message &&
          <div style={{color: 'red', marginBottom: '20px'}}>{this.props.message}</div>
        }

        <Elements stripe={stripePromise} options={stripeOptions}>
          <StripeForm />
        </Elements>


        <div style={{textAlign: 'center', marginTop: '30px', fontSize: '13px'}}>
          <img src="/images/stripe-logo.png" alt="Stripe" style={{width: '60px', height: 'auto'}} />
          <div>Credit card processing is handled by <a href="http://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>, and all communications are SSL encrypted.</div>
        </div>
      </div>
    )
  }

}
