import { useState } from "react"
import AppConstants from '../../api/constants/AppConstants'
import StrategyWidget from '../retail/StrategyWidget'
import SessionStore from "../../api/stores/SessionStore"
import ExpandIcon from "../common/icons/ExpandIcon"
import ContractIcon from "../common/icons/ContractIcon"
import PreviewMessage from "../myLot/PreviewMessage"

const ValuationStrategyWidget = ({ valuation }) => {
  const [isCollapsed, setIsCollapsed] = useState(SessionStore.collapsedLotSenseStrategyWidgetValuations === true)

  return (
    <section className="card valuation-section" id="turn_optimizer">
      <div className="split-row" style={{justifyContent: 'space-between'}}>
        <h2>{AppConstants.providerNames['turn_optimizer']}</h2>
        <a href="#toggle" onClick={(e) => {
          e.preventDefault()
          setIsCollapsed(!isCollapsed)
          SessionStore.setCollapsedLotSenseStrategyWidgetValuations(!isCollapsed)
        }}>
          {
            isCollapsed ?
            <ExpandIcon width={28} height={28} color='#aaa' /> : <ContractIcon width={28} height={28} color='#aaa' />
          }
        </a>
      </div>
      {
        !isCollapsed &&
        <>
          <h3 style={{marginBottom: '-20px'}}>{[valuation.year, valuation.make, valuation.model, valuation.series].join(' ')}</h3>
          {
            SessionStore.isPreviewingLotSense() &&
            <div style={{marginTop: '30px', marginBottom: '-20px'}}>
              <PreviewMessage />
            </div>
          }
          <StrategyWidget valuation={valuation} className='strategy-widget-valuation' showTitle={false} useManualACV={true} />
        </>
      }
    </section>
  )
}

export default ValuationStrategyWidget
