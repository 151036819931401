import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Formatters from '../../api/constants/Formatters'
import pluralize from 'pluralize'
import moment from 'moment'
import ListingImage from '../../components/common/ListingImage'
import VehicleTransactionDataFormModal from '../../components/myLot/VehicleTransactionDataFormModal'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import ValuationStore from '../../api/stores/ValuationStore'
import Alert from '../../components/myLot/Alert'
import LotStateButton from '../../components/myLot/LotStateButton'
import EditIconCircle from '../../components/common/icons/EditIconCircle'
import RetailListingsModal from '../../components/myLot/RetailListingsModal'
import WarningCircleIcon from '../../components/common/icons/WarningCircleIcon'
import SnoozeAlertsToggle from '../../components/myLot/SnoozeAlertsToggle'

const ListingRow = ({ listing, options = {}}) => {
  const now = moment()

  const [isShowingTransactionDataModal, setIsShowingTransactionDataModal] = useState(false)
  const [isShowingRegionalListingsModal, setIsShowingRegionalListingsModal] = useState(false)
  const [lotVehicle, setLotVehicle] = useState(listing)
  const clientID = lotVehicle.uuid

  const onDataChange = useCallback(() => {
    const data = ValuationStore.myLotVehiclesUpdated.dataWithID(clientID)
    setLotVehicle({...lotVehicle, ...data})
  }, [clientID])

  const onLotVehicleDeleted = useCallback(() => {
    setLotVehicle({...lotVehicle, isDeleted: true})
  }, [clientID])

  useEffect(() => {
    const lotVehicleEv = `lot_vehicle_updated_${clientID}`
    const lotVehicleDeletedEv = `lot_vehicle_deleted_${clientID}`
    ValuationStore.on(lotVehicleEv, onDataChange)
    ValuationStore.on(lotVehicleDeletedEv, onLotVehicleDeleted)

    return () => {
      ValuationStore.removeListener(lotVehicleEv, onDataChange)
      ValuationStore.removeListener(lotVehicleDeletedEv, onLotVehicleDeleted)
    }
  }, [onDataChange, clientID])

  const url = MyLotFunctions.myLotVehicleURL(lotVehicle)

  const showTransactiondDataModal = useCallback((e) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    setIsShowingTransactionDataModal(true)
  }, [])

  if (lotVehicle.isDeleted) {
    return null
  }

  return (
    <div className="card-list-row my-lot-vehicle-row">
      <Link to={url} target={options.target}>
        <div className="thumbnail">
          <ListingImage src={lotVehicle.photo_urls[0]} />
        </div>
        <div className="my-lot-vehicle-body">
          <div className='row'>
            <div className='listing-basics'>
              <h3 className='year-make'>{lotVehicle.year} {lotVehicle.make}</h3>
              <h3 className='model'>{lotVehicle.model}</h3>

              {
                lotVehicle.trim &&
                <div className="secondary-text trim">{lotVehicle.trim}</div>
              }

              {
                Boolean(lotVehicle.vin) &&
                <div className="secondary-text">{lotVehicle.vin}</div>
              }
              {
                Boolean(lotVehicle.mileage && lotVehicle.mileage > 0) &&
                <div className="secondary-text">{lotVehicle.mileage > 0 ? Formatters.formatThousands(lotVehicle.mileage) + ' miles' : ''}</div>
              }
            </div>

            <div className='listing-dol'>
              {
                lotVehicle.sold_at != null &&
                <div>Sold {pluralize('day', now.diff(lotVehicle.sold_at, 'days'), true)} ago</div>
              }
              {
                lotVehicle.days_on_lot != null &&
                <div style={{color: MyLotFunctions.colorForDaysOnLot(lotVehicle.days_on_lot)}}>{pluralize('day', lotVehicle.days_on_lot, true)} on lot</div>
              }
            </div>

            <div className='listing-price-changes'>
              <div>{pluralize('price change', lotVehicle.num_price_changes, true)}</div>
              {
                lotVehicle.num_price_changes > 1 && lotVehicle.total_price_change_amount_cents &&
                <div className='secondary-text'>Total change {Formatters.formatCurrencyCents(lotVehicle.total_price_change_amount_cents)}</div>
              }

              {
                lotVehicle.last_price_change_date != null && lotVehicle.last_price_change_amount_cents != null &&
                <div className='secondary-text'>
                  <div>Last: {pluralize('day', now.diff(lotVehicle.last_price_change_date, 'days'), true)} ago</div>
                </div>
              }
            </div>

            {
              lotVehicle.state === 'sold' &&
              <div className='profit'>
                {
                  lotVehicle.profit !== null &&
                  <>
                    <div className='profit-amount'>{Formatters.formatCurrency(lotVehicle.profit)}</div>
                    <div className='secondary-text' style={{marginTop: '-4px'}}>Profit</div>
                  </>
                }
              </div>
            }

            {
              lotVehicle.state !== 'sold' &&
              <div className={`comps ${lotVehicle.region_listings_count === 0 ? 'no-comps' : ''}`}>
                <button onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (lotVehicle.region_listings_count > 0) {
                    setIsShowingRegionalListingsModal(true)
                  }
                  }} style={{opacity: lotVehicle.region_listings_count > 0 ? 1 : 0.5}}>
                  {lotVehicle.region_listings_count} for sale nearby
                </button>
              </div>
            }


            {/* FIXME: why use price_cents vs sale_price_cents when sold? Implications for 'Set Price' button? */}

            <div className='pricing'>
              {
                Boolean(lotVehicle.sale_price_cents) &&
                <>
                  <div className='list-price'>{Formatters.formatCurrencyCents(lotVehicle.sale_price_cents)}</div>
                  <div className='secondary-text'>Sold at</div>
                  {
                    lotVehicle.portion_of_market != null &&
                    <div>{Math.round(lotVehicle.portion_of_market * 100)}% of market</div>
                  }
                </>
              }

              {
                !lotVehicle.sale_price_cents && lotVehicle.price_cents != null &&
                <>
                  <div className='list-price'>{Formatters.formatCurrencyCents(lotVehicle.price_cents)}</div>
                  <div className='secondary-text'>Listed price</div>
                  {
                    lotVehicle.portion_of_market != null &&
                    <div>{Math.round(lotVehicle.portion_of_market * 100)}% of market</div>
                  }
                </>
              }

              {
                Boolean(lotVehicle.acv) &&
                <div className='list-acv'><span className='secondary-text'>ACV</span> {Formatters.formatCurrency(lotVehicle.acv)}</div>
              }
              {
                lotVehicle.costs === 0 && lotVehicle.acv !== null &&
                <div className='secondary-text' style={{color: '#888', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                  <WarningCircleIcon size={16} color='#888' style={{marginRight: '4px'}} />
                  Costs are zero</div>
              }
            </div>
            <div className='edit'>
              <button onClick={showTransactiondDataModal}>
                <EditIconCircle width={'30px'} height={'30px'} />
              </button>
            </div>
          </div>

          <div className='row capsules'>
            <LotStateButton lotVehicleState={lotVehicle.state} onClick={showTransactiondDataModal} />
            {lotVehicle.alerts.map((alert, i) => <Alert key={i} alert={alert} year={lotVehicle.year} make={lotVehicle.make} model={lotVehicle.model} trim={lotVehicle.trim} />)}
            { options.showSnoozeAlertsToggle && <SnoozeAlertsToggle clientID={clientID} lotVehicle={lotVehicle} /> }
          </div>

        </div>
      </Link>

      {
        isShowingTransactionDataModal &&
        <VehicleTransactionDataFormModal onCancel={() => setIsShowingTransactionDataModal(false)} onDelete={() => setIsShowingTransactionDataModal(false)} vehicle={{ uuid: lotVehicle.uuid, year: lotVehicle.year, make: lotVehicle.make, model: lotVehicle.model, lot_vehicle: lotVehicle }} clientID={clientID} />
      }

      {
        isShowingRegionalListingsModal &&
        <RetailListingsModal
          onClose={() => setIsShowingRegionalListingsModal(false)}
          year={lotVehicle.year}
          make={lotVehicle.make}
          model={lotVehicle.model}
          trim={lotVehicle.trim}
          zip={lotVehicle.zip_code}
        />
      }
    </div>
  )
}

export default ListingRow
