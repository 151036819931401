import React, { useState, useCallback } from 'react'
import MyLotVehiclesSortControl from './MyLotVehiclesSortControl'
import VehiclesList from '../../components/myLot/VehiclesList'
import MyLotRow from './ListingRow'

const renderRow = ({ listing, options }) => (
  <MyLotRow
    key={listing.uuid}
    listing={listing}
    options={options}
  />
);

const VehiclesListContainer = ({
  title,
  subtitle,
  defaultOrder = 'created_at DESC',
  getScrollParent,
  excludedSortOptions,
  rowOptions,
  query,
  headerStyle,
  containerStyle,
}) => {
  const [
    {
      order,
    },
    setState,
  ] = useState({
    order: defaultOrder,
  })

  const setOrder = useCallback((order) => {
    setState(prev => ({ ...prev, order }))
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, minHeight: '800px', ...containerStyle }}>
      <div className='split-row' style={{...headerStyle}}>
        <div>
          <h2 className='card-title' style={{marginBottom: 0}}>{title}</h2>
          {
            Boolean(subtitle) &&
            <div style={{marginTop: '-5px', color: '#888'}}>{subtitle}</div>
          }
        </div>
        <div className='split-row'>
          <div className='secondary-text'>Sort by</div>
          <MyLotVehiclesSortControl
            selected={order}
            setSelected={setOrder}
            excludedOptions={excludedSortOptions}
          />
        </div>
      </div>
      <VehiclesList
        getScrollParent={getScrollParent}
        order={order}
        excludedSortOptions={excludedSortOptions}
        renderRow={renderRow}
        rowOptions={rowOptions}
        query={query}
      />
    </div>
  )
}

export default VehiclesListContainer
