import { useEffect, useState, useCallback } from 'react'
import ConnectPreferredLotInventoryProviderModal from './ConnectPreferredLotInventoryProviderModal'
import useMyLotSettings from '../../api/hooks/useMyLotSettings'

const ConnectPreferredLotInventoryProviderButton = ({ buttonText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [
    {
      onboarding: {
        lotInventoryStatus,
      },
    },
    updateSettings,
    isUpdatingSettings,
  ] = useMyLotSettings()

  useEffect(() => {
    if (lotInventoryStatus === 'interested') {
      setIsModalOpen(false)
    }
  }, [lotInventoryStatus])

  const onCancelClick = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const onConfirm = useCallback((providerName) => {
    updateSettings({ lotInventoryStatus: 'interested', providerName: providerName })
  }, [updateSettings])

  return (
    <>
      <button className='link' disabled={isUpdatingSettings} onClick={() => setIsModalOpen(true)}>{buttonText}</button>
      {
        isModalOpen &&
        <ConnectPreferredLotInventoryProviderModal
          isSaving={isUpdatingSettings}
          onCancel={onCancelClick}
          onConfirm={onConfirm}
        />
      }
    </>
  )
}

export default ConnectPreferredLotInventoryProviderButton
