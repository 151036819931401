import React from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import UserActionCreators from '../../api/actions/UserActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import Spinner from '../common/Spinner'
import Formatters from '../../api/constants/Formatters'
import AppConstants from '../../api/constants/AppConstants'
import platformFunctions from '../../platformFunctions'
import ProviderLogo from '../common/icons/providers/ProviderLogo'
import PaymentFormModal from '../settings/PaymentFormModal'

const CARBLY_PLUS_BUNDLE_SKU = AppConstants.skuMappings['carbly_plus_bundle']

export default class OfferingUpsellModal extends React.Component {
  state = {
    isLoadingCostForSelected: true,
    costForSelected: null,
    pricingForOfferingsErrors: null,
    isAddingOffering: false,
    addOfferingErrors: null,
    isShowingAddPaymentModal: false,
    isLoadingAvailableOfferings: true,
    availableOfferings: null,
  }
  componentDidMount() {
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("account_details_received", this.accountDetailsReceived)
    SessionStore.on("offerings_added", this.offeringAdded)
    SessionStore.on("user_data_changed", this.userDataChanged)
    SessionStore.on("available_offerings_received", this.availableOfferingsReceived)

    UserActionCreators.loadAccountDetails()
    UserActionCreators.loadPricingForOfferings([this.props.offering.sku])
    UserActionCreators.loadAvailableOfferings()

    platformFunctions.logAnalyticsEvent("User", "Opened Valuation Add Offering Modal")

    if (this.props.offering?.sku === AppConstants.skuMappings.auctionsPlus) {
      UserActionCreators.createMetric(AppConstants.metricNames['auctions_plus_upsell'])
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("account_details_received", this.accountDetailsReceived)
    SessionStore.removeListener("offerings_added", this.offeringAdded)
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    SessionStore.removeListener("available_offerings_received", this.availableOfferingsReceived)
  }

  accountDetailsReceived = () => {
    this.setState({
      isLoadingAccountDetails: false,
      accountDetails: SessionStore.accountDetails
    })
  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    const pricingForOfferingsErrors = SessionStore.pricingForOfferingsErrors

    this.setState({
      isLoadingCostForSelected: false,
      costForSelected: pricingForOfferings,
      pricingForOfferingsErrors: pricingForOfferingsErrors
    })

    if (this.props.onSelectedOfferingsChange) {
      this.props.onSelectedOfferingsChange(this.state.selectedOfferings, pricingForOfferings)
    }
    if (this.props.onCostForSelectedOfferingsLoadingStateChange) {
      this.props.onCostForSelectedOfferingsLoadingStateChange(false)
    }
  }

  availableOfferingsReceived = () => {
    this.setState({
      isLoadingAvailableOfferings: false,
      availableOfferings: SessionStore.availableOfferings?.offerings
    })
  }

  userDataChanged = () => {
    const user = SessionStore.user
    if (this.state.isShowingAddPaymentModal) {
      // Free user just successfully added a payment method
      this.setState({
        isShowingAddPaymentModal: false
      })
      this.addOffering()
    } else {
      this.setState({ user: SessionStore.user })
    }
  }


  addOffering = () => {
    const { offering } = this.props

    const needsPaymentMethod = SessionStore.needsPaymentMethod()
    if (needsPaymentMethod) {
      this.setState({
        isShowingAddPaymentModal: true
      })
    } else {
      UserActionCreators.addOfferings([offering.sku])
      this.setState({
        isAddingOffering: true
      })
    }
  }

  offeringAdded = () => {
    var addOfferingErrors = null
    if (SessionStore.addOfferingsErrorMessage) {
      addOfferingErrors = SessionStore.addOfferingsErrorMessage.join(' ')
    }
    this.setState({
      isAddOfferingComplete: true,
      isAddingOffering: false,
      addOfferingErrors: addOfferingErrors
    })

    if (!addOfferingErrors) {
      setTimeout(() => {
        this.props.onPurchase()
      }, 400)
    }
  }


  render() {
    const { offering } = this.props
    const { isLoadingCostForSelected, costForSelected, isLoadingAccountDetails, accountDetails, isAddingOffering, isAddOfferingComplete, addOfferingErrors, pricingForOfferingsErrors, isLoadingAvailableOfferings, availableOfferings } = this.state

    const isLoading = isLoadingCostForSelected || isLoadingAccountDetails || isLoadingAvailableOfferings

    const user = SessionStore.user
    let providerKey = offering.provider_key
    if (!offering.provider_key && offering.sku === AppConstants.skuMappings['marketTracker']) {
      providerKey = 'market_tracker'
    } else if (!offering.provider_key && offering.sku === AppConstants.skuMappings['auctions_plus']) {
      providerKey = 'auctions_plus'
    } else if (!offering.provider_key && offering.sku === AppConstants.skuMappings['my_lot']) {
      providerKey = 'lot_sense'
    }

    let bundleSubsetMessages = []
    console.log("providerKey", providerKey);
    if (providerKey === 'my_lot') {
      const includedBundleSKUS = availableOfferings?.find((offering) => offering.sku === CARBLY_PLUS_BUNDLE_SKU)?.replaced_skus || []
      for (let bundleSku of includedBundleSKUS) {
        console.log("bundleSku", bundleSku);  
        if (Boolean(accountDetails?.account_offerings?.find((offering) => offering.sku === bundleSku))) {
          let offeringKey = Object.keys(AppConstants.skuMappings).find(key => AppConstants.skuMappings[key] === bundleSku)
          // Hack because we have inconsistent naming keys for market tracker
          offeringKey = offeringKey === 'marketTracker' ? 'regional_report' : offeringKey
          bundleSubsetMessages.push(AppConstants.providerNames[offeringKey])

        }
      }
    }


    const isTrialing = SessionStore.isTrialing()
    const isPreviewing = user?.offering_previews && user.offering_previews[this.props.offering.sku]
    const isDivergent = accountDetails?.divergent_offerings === true
    const isCustomBilling = accountDetails?.custom_billing === true
    const isYearly = accountDetails?.billing_interval === 'year'
    const prorationAmount = costForSelected?.proration_amount
    const isLLM = providerKey === 'universe'

    var nonAuxUsersCount = 0
    if (accountDetails?.user_details) {
      nonAuxUsersCount = accountDetails?.user_details.filter((item) => item.auxiliary === false).length
    }

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px', maxWidth: isLLM ? '1000px' : '800px'}}>
        <ModalHeader className='modal-header'>Add {AppConstants.providerNames[providerKey]}</ModalHeader>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{flexGrow: 1, flexShrink: 1}}>
              {
                isLoading &&
                <Spinner />
              }

              {
                !isLoading && !isDivergent && !isCustomBilling && !addOfferingErrors &&
                <div style={{marginBottom: '20px'}}>

                  <div style={{textAlign: 'center', marginBottom: '10px'}}>
                    <ProviderLogo providerKey={providerKey} width={this.props.logoWidth || 200} height={this.props.logoHeight || null} />
                  </div>
                  {
                    // Description
                    AppConstants.providerDescriptions[providerKey] &&
                    <div style={{textAlign: isLLM ? 'left' : 'center'}}>{ AppConstants.providerDescriptions[providerKey] }</div>
                  }

                  {
                    isLLM &&
                    <div style={{marginTop: '20px'}}>
                      <ul>
                        <li>Adjustable distance lets you look locally or nationally for similar retail listings</li>
                        <li>View full comp details with links to dealer website listings</li>
                        <li>Detailed dealer price change histories</li>
                        <li>National asking price histories and trends</li>
                        <li>When you scan a VIN or view an auction listing, Carbly will let you know if the vehicle has recently been listed at retail</li>
                      </ul>
                    </div>
                  }

                  {
                    Boolean(bundleSubsetMessages.length) &&
                    <div className='secondary-text' style={{textAlign: 'center'}}>Your account already includes the following products from this bundle: {bundleSubsetMessages.join(', ')}</div>
                  }

                  {
                    !pricingForOfferingsErrors &&
                    <div>
                      <div style={{textAlign: 'center'}}>
                        <Button disabled={isAddingOffering} onClick={this.addOffering} color="primary" style={{height: '40px', marginTop: '20px', marginBottom: '10px', width: '320px'}}>{isAddingOffering ? <Spinner className="spinner-button" /> : `Additional ${Formatters.formatCurrencyCents(costForSelected['incremental_price'])} per ${isYearly ? 'year' : 'month'}`}</Button>
                      </div>

                      {
                        !isTrialing && prorationAmount &&
                        <div className="secondary-text" style={{textAlign: 'center', marginTop: '5px', color: 'red'}}>
                        You will be immediately charged {Formatters.formatCurrencyCents(prorationAmount)} for the prorated amount of your current billing cycle</div>
                      }

                      <div className='secondary-text' style={{textAlign: 'center'}}>Sales tax may apply</div>

                      {
                        Boolean(costForSelected['pricing_message']) &&
                        <div style={{textAlign: 'center'}}>{costForSelected['pricing_message']}</div>
                      }

                      {
                        nonAuxUsersCount > 1 &&
                        <div style={{textAlign: 'center'}}>Price includes the cost for {nonAuxUsersCount} users</div>
                      }

                      {
                        isTrialing &&
                        <div style={{textAlign: 'center'}}>You will not be charged until your free trial ends</div>
                      }

                      {
                        isPreviewing && !isTrialing &&
                        <div style={{textAlign: 'center'}}>You will not be charged until your free preview ends</div>
                      }
                    </div>
                  }

                  {
                    Boolean(pricingForOfferingsErrors) &&
                    <div style={{margin: '20px 0', color: 'red', textAlign: 'center'}}>{pricingForOfferingsErrors.join('. ')}</div>
                  }
                </div>
              }

              {
                !isLoading && Boolean(isDivergent || isCustomBilling) &&
                <div style={{margin: '20px 0'}}>Your account has {isDivergent ? 'users with varying options' : 'custom billing'}.  If you'd like to make a change, please give us a call and we'd be happy to help:  {AppConstants.phoneNumber}</div>
              }

              {
                addOfferingErrors &&
                <div style={{color: 'red', textAlign: 'center', margin: '40px 0'}}>{addOfferingErrors}</div>
              }



              <div style={{textAlign: 'center'}}>
                <a href="#cancel" style={{textAlign: 'center'}} onClick={(e) => {e.preventDefault(); this.props.onDone()}}>Cancel</a>
              </div>
            </div>


            {
              isLLM &&
              <div style={{flexGrow: 0, flexShrink: 0, marginLeft: '40px'}}>
                <img src="/images/llm-upsell-screenshot.png" style={{width: '500px', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 30px rgba(0,0,0,0.2)'}} />
              </div>
            }

        {
          this.state.isShowingAddPaymentModal &&
          <PaymentFormModal isAccountOwner={true} onCancel={() => this.setState({ isShowingAddPaymentModal: false})} title='Add a Payment Method' />
        }


          </div>
        </ModalBody>
      </Modal>
    )

  }


}
