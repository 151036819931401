import { memo, useCallback, useState } from 'react'
import Spinner from '../../components/common/Spinner'
import LineChart from '../../components/myLot/LineChart'
import BarChart from '../../components/myLot/BarChart'
import useMyLotReport from '../../api/hooks/useMyLotReport'
import ChartSection from './ChartSection'
import Header from './Header'
import componentProps from './util/componentProps'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'
import SessionStore from '../../api/stores/SessionStore'

const chartSpacing = 20
const snapshotSpacing = 8

const snapshots = [
  {
    key: 'num_on_lot',
    categoryKey: 'active_vehicles',
    title: 'Current Inventory',
  },
  {
    key: 'num_sold',
    categoryKey: 'sold_vehicles',
    title: 'Recently Sold',
  },
  {
    key: 'avg_days_on_lot',
    categoryKey: 'age_active',
    title: 'Days on Lot - Current',
  },
  {
    key: 'avg_days_on_lot_sold',
    categoryKey: 'age_sold',
    title: 'Days on Lot - Recently Sold',
  },
  {
    key: 'sales_rate',
    categoryKey: 'sales_rate',
    title: 'Sales Rate',
  },
  {
    key: 'retail_value',
    categoryKey: 'inventory_value',
    title: 'Current Retail Value',
  },
]

const categorizedCharts = [
  {
    title: 'Current Inventory',
    charts: [
      {
        key: 'trends.num_on_lot',
        title: 'Number of Current Units Trend',
        Chart: LineChart,
      },
      {
        key: 'groups.days_on_lot.num_on_lot',
        title: 'Current Units by Days on Lot',
        Chart: BarChart,
      },
      {
        key: 'groups.body_style.num_on_lot',
        title: 'Current Units by Body Style',
        Chart: BarChart,
      },
      {
        key: 'groups.make.num_on_lot',
        title: 'Current Units by Make',
        Chart: BarChart,
      },
      {
        key: 'groups.price.num_on_lot',
        title: 'Current Units by Price',
        Chart: BarChart,
      },
    ],
  },

  {
    title: 'Recently Sold',
    key: 'sold_vehicles',
    charts: [
      {
        key: 'trends.num_sold',
        title: 'Number of Sold Units Trend',
        Chart: LineChart,
      },
      {
        key: 'groups.days_on_lot.num_sold',
        title: 'Sold Units by Days on Lot',
        Chart: BarChart,
      },
      {
        key: 'groups.body_style.num_sold',
        title: 'Sold Units by Body Style',
        Chart: BarChart,
      },
      {
        key: 'groups.make.num_sold',
        title: 'Sold Units by Make',
        Chart: BarChart,
      },
      {
        key: 'groups.price.num_sold',
        title: 'Sold Vehicles by Price',
        Chart: BarChart,
      },
    ]
  },

  {
    title: 'Days on Lot - Current',
    key: 'age_active',
    charts: [
      {
        key: 'trends.avg_days_on_lot',
        title: 'Current Units Average Days on Lot Trend',
        Chart: LineChart,
      },
      {
        key: 'groups.body_style.avg_days_on_lot',
        title: 'Current Units Average Days on Lot by Body Style',
        Chart: BarChart,
      },
      {
        key: 'groups.make.avg_days_on_lot',
        title: 'Current Units Average Days on Lot by Make',
        Chart: BarChart,
      },
      {
        key: 'groups.price.avg_days_on_lot',
        title: 'Current Units Average Days on Lot by Price',
        Chart: BarChart,
      },
    ]
  },

  {
    title: 'Days on Lot - Recently Sold',
    key: 'age_sold',
    charts: [
      {
        key: 'trends.avg_days_on_lot_sold',
        title: 'Sold Units Average Days on Lot Trend',
        Chart: LineChart,
      },
      {
        key: 'groups.body_style.avg_days_on_lot_sold',
        title: 'Sold Units Average Days on Lot by Body Style',
        Chart: BarChart,
      },
      {
        key: 'groups.make.avg_days_on_lot_sold',
        title: 'Sold Units Average Days on Lot by Make',
        Chart: BarChart,
      },
      {
        key: 'groups.price.avg_days_on_lot_sold',
        title: 'Sold Units Average Days on Lot by Price',
        Chart: BarChart,
      },
    ]
  },

  {
    title: 'Sales Rate',
    key: 'sales_rate',
    charts: [
      {
        key: 'trends.sales_rate',
        title: 'Sales Rate Trend',
        Chart: LineChart,
      },
      {
        key: 'groups.days_on_lot.sales_rate',
        title: 'Sales Rate by Days on Lot',
        Chart: BarChart,
      },
      {
        key: 'groups.body_style.sales_rate',
        title: 'Sales Rate by Body Style',
        Chart: BarChart,
      },
      {
        key: 'groups.make.sales_rate',
        title: 'Sales Rate by Make',
        Chart: BarChart,
      },
      {
        key: 'groups.price.sales_rate',
        title: 'Sales Rate by Price',
        Chart: BarChart,
      },
    ]
  },

  {
    title: 'Current Retail Value',
    key: 'inventory_value',
    charts: [
      {
        key: 'trends.retail_value',
        title: 'Current Inventory Value Trend',
        Chart: LineChart,
      },
      {
        key: 'groups.days_on_lot.retail_value',
        title: 'Current Inventory Value by Days on Lot',
        Chart: BarChart,
      },
      {
        key: 'groups.body_style.retail_value',
        title: 'Current Inventory Value by Body Style',
        Chart: BarChart,
      },
      {
        key: 'groups.make.retail_value',
        title: 'Current Inventory Value by Make',
        Chart: BarChart,
      },
    ]
  }

]

const SnapshotSection = memo(({ data, dataKey, title, style, categoryKey }) => {
  const props = componentProps(data, dataKey)
  const trendsKeySplit = dataKey.split('.')
  const trendsDataKey = trendsKeySplit[trendsKeySplit.length - 1]
  const trendsData = data.lot_analysis.trends
  const { formattedCurrent, deltaMessage, current } = props

  return (
    <a href={`#${categoryKey}`} className='my-lot-analysis-snapshot-section' style={style}>
      <div style={{ border: '1px solid #ccc', borderRadius: 6, padding: 8, boxShadow: '0 2px 12px rgba(0,0,0,0.1)', backgroundColor: '#eee' }}>
        <div className='split-row' style={{alignItems: 'flex-start'}}>
          <div>
            <div style={{ fontWeight: 'bold', color: '#222', fontSize: '1.1rem' }}>{title}</div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '10px'}}>
              <span style={{fontSize: '1.5rem', letterSpacing: '-0.5px', color: current  === null ? '#aaa' : 'inherit'}}>{formattedCurrent}</span>
              <span style={{fontSize: '0.95rem', color: '#888'}}>{deltaMessage}</span>
            </div>
          </div>

          <div style={{width: '60px', height: '16px', marginLeft: '10px'}}>
            <LineChart data={
              {
                datasets: [
                  {data: trendsData}
                ]
              }
            }
            options={{
              parsing: {
                xAxisKey: 'date',
                yAxisKey: trendsDataKey,
              },
              layout: {
                padding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                },
              },
              plugins: {
                datalabels: {
                  display: false,
                },
                tooltip: {
                  enabled: false
                },
              },
              scales: {
                x: {
                  display: false,
                },
              },
              elements: {
                line: {
                  borderWidth: 2,
                  borderColor: '#aaa',
                  fill: false,
                },
                point: {
                  borderWidth: 0,
                  radius: 2,
                  backgroundColor: '#aaa',
                  hoverRadius: 0,
                  hoverBorderWidth: 0,
                },
              },
            }} />
          </div>
        </div>
      </div>
    </a>
  )
})

const Analysis = () => {
  const {
    data: report,
    isLoading: isLoadingReport,
  } = useMyLotReport()

  const [pinnedChartKeys, setPinnedChartKeys] = useState(SessionStore.user?.lot_analytics_pinned_charts || [])

  const togglePin = useCallback((key) => {
    let newPinnedKeys = [...pinnedChartKeys]
    if (newPinnedKeys.includes(key)) {
      newPinnedKeys = newPinnedKeys.filter(k => k !== key)
    } else {
      newPinnedKeys.push(key)
    }
    setPinnedChartKeys(newPinnedKeys)
    UserActionCreators.updateUser({lot_analytics_pinned_charts: newPinnedKeys})
  }, [pinnedChartKeys])

  UserActionCreators.createMetric(AppConstants.metricNames['my_lot_view_lot_analysis'])

  return (
    <div>
      <Header title='Lot Analysis' />
      <div>
        {
          !report && isLoadingReport ?
          <Spinner /> :
          <>
            {
              report &&
              <div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: snapshotSpacing * 2, marginRight: -snapshotSpacing, marginLeft: -snapshotSpacing }}>
                    {
                      snapshots.map(({ key, title, categoryKey }) => (
                        <SnapshotSection
                          style={{ width: `${100 / 3}%`, paddingLeft: snapshotSpacing, paddingRight: snapshotSpacing }}
                          key={key}
                          categoryKey={categoryKey}
                          title={title}
                          data={report}
                          dataKey={`lot_analysis.snapshots.${key}`}
                        />
                      ))
                    }
                  </div>

                  {
                    pinnedChartKeys.length > 0 &&
                    <div style={{marginBottom: '40px'}}>
                      <h2 style={{borderBottom: '6px solid #111', marginBottom: '20px'}}>Pinned Charts</h2>
                      <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 30, marginRight: -chartSpacing, marginLeft: -chartSpacing }}>
                        {
                          pinnedChartKeys.map(pinnedKey => {
                            const pinnedChart = categorizedCharts.flatMap(category => category.charts).find(({ key }) => key === pinnedKey)
                            return (
                              <ChartSection
                                style={{ width: `${100 / 2}%`, paddingLeft: chartSpacing, paddingRight: chartSpacing }}
                                key={pinnedChart.key}
                                title={pinnedChart.title}
                                Chart={pinnedChart.Chart}
                                data={report}
                                pinOptions={{pinnable: true, key: pinnedChart.key, isPinned: true, onPin: () => {togglePin(pinnedChart.key)}}}
                                dataKey={`lot_analysis.${pinnedChart.key}`}
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  }

                  <div>
                    {
                      categorizedCharts.map((category, index) => {
                        return (
                          <div style={{marginBottom: '80px'}} id={category.key} key={index}>
                            <h2 style={{borderBottom: '6px solid #111', marginBottom: '20px'}}>{category.title}</h2>
                            <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 30, marginRight: -chartSpacing, marginLeft: -chartSpacing }}>
                              {
                                category.charts.map(({ key, title, Chart }) => (
                                  <ChartSection
                                    height={200}
                                    style={{ width: `${100 / 2}%`, paddingLeft: chartSpacing, paddingRight: chartSpacing }}
                                    key={key}
                                    title={title}
                                    Chart={Chart}
                                    data={report}
                                    pinOptions={{pinnable: true, key: key, isPinned: pinnedChartKeys.includes(key), onPin: () => {togglePin(key)}}}
                                    dataKey={`lot_analysis.${key}`}
                                  />
                                ))
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default Analysis
