import { useCallback, useState } from 'react'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ZipCodeEntryField from '../../components/common/ZipCodeEntryField'
import { reactSelectStyles } from './constants'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AppConstants from '../../api/constants/AppConstants'

const RegionOptionsModal = ({ title, radiusOptions, radius, zip, onClose, onSave }) => {
  const [selectedRadius, setSelectedRadius] = useState(() => radiusOptions.find(item => item.value === radius))

  const onSaveClick = useCallback(() => {
    if (radius !== selectedRadius.value) {
      UserActionCreators.createMetric(AppConstants.metricNames['my_lot_view_settings_change_radius'], selectedRadius.value)
    }

    onSave({
      radius: selectedRadius.value,
      initialRadius: radius,
      initialZip: zip,
    })
  }, [onSave, selectedRadius, zip, radius])

  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
      <form onSubmit={onSaveClick}>
        <ModalHeader className='modal-header'>
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="split-row split-row-left">
            <div style={{marginRight: '10px'}}>Includes dealers within</div>
            <div style={{ width: 200 }}>
              <Select
                styles={reactSelectStyles}
                name='radius'
                className='react-select'
                isSearchable={false}
                onChange={setSelectedRadius}
                value={selectedRadius}
                options={radiusOptions}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' onClick={onSaveClick}>Save Options</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default RegionOptionsModal
