import { useCallback, useMemo, useState } from 'react'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import MyLotFunctions from '../../api/constants/MyLotFunctions'

const OTHER_VALUE = 'Other...'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
    padding: '10px'
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}

const ConnectPreferredLotInventoryProviderModal = ({ isSaving, onCancel, onConfirm }) => {
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [manuallyEnteredProvider, setManuallyEnteredProvider] = useState(null)
  const onCancelClick = useCallback(e => {
    e.preventDefault()
    onCancel()
  }, [onCancel])

  const isProviderEntered = useMemo(() => {
    if (selectedProvider?.value === OTHER_VALUE && manuallyEnteredProvider) {
      return true
    } else if (selectedProvider?.value !== OTHER_VALUE) {
      return true
    }
    return false
  }, [selectedProvider, manuallyEnteredProvider])

  return (
    <Modal isOpen centered toggle={onCancel} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '700px'}}>
      <ModalHeader className='modal-header'>
        Connect Your Inventory Management System
      </ModalHeader>
      <ModalBody>
        <div>
          <p>Carbly supports direct connection to many inventory management systems. This allows you to automatically sync your current inventory to Carbly for more powerful and accurate lot insights and vehicle recommendations.</p>

          <p>We support <strong>integrations over 140 different systems</strong> including Frazer, CarsForSale, DealerCenter, Dealer.com, Cars.com, Dealer Inspire, AutoManager, AutoRevo, Dealer Car Search, Wayne Reaves, and many more!</p>

          <div style={{textAlign: 'center'}}>
            <img src="/images/ims-providers-sample.png" alt="IMS example providers" style={{width: '550px', height: '56px', marginBottom: '10px'}} />
          </div>

          <p style={{marginTop: '40px'}}>If you would like your inventory to automatically show up in Carbly, just select your provider and click the "Request Connection" button below and we will get the process started. You'll get an email from Carbly and our partners.</p>

          <div style={{margin: '20px 10px'}}>
            <Select
              styles={customStyles}
              name="provider"
              value={selectedProvider}
              onChange={(provider) => {
                setSelectedProvider(provider)
              }}
              placeholder='Select your inventory management system...'
              options={
                [OTHER_VALUE, ...MyLotFunctions.ICCProviders].map((provider) => {
                  return {value: provider, label: provider}
                })
              }
            />

            {
              selectedProvider?.value === OTHER_VALUE &&
              <input type="text" className="field" name="manual-provider" maxLength={100} onChange={e => setManuallyEnteredProvider(e.target.value)} value={manuallyEnteredProvider} placeholder="Enter provider name" />
            }
          </div>


          
        </div>

        <div style={{textAlign: 'center'}}>
          <Button type='button' color='primary' onClick={() => onConfirm(selectedProvider.value)} disabled={isSaving || !isProviderEntered} style={{margin: '0 auto'}}>Request Connection</Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <a href="#cancel" onClick={onCancelClick}>Cancel</a>
      </ModalFooter>
    </Modal>
  )
}

export default ConnectPreferredLotInventoryProviderModal
