import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import WebAPIUtils from '../../api/actions/WebAPIUtils'
import RemoteConstants from '../../api/constants/RemoteConstants'
import Spinner from '../common/Spinner'
import UserActionCreators from '../../api/actions/UserActionCreators'

const HEADERS = {'Accept': 'application/json', 'Content-Type': 'application/json'}

export default function StripeForm() {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [user, setUser] = useState(SessionStore.user)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    SessionStore.on('cc_updated', paymentDetailsChanged)
    SessionStore.on('cc_retried', paymentDetailsChanged)
  })

  const paymentDetailsChanged = () => {
    setUser(SessionStore.user)
    setErrorMessage(SessionStore.updateCCErrorMessage)
    setSuccessMessage(SessionStore.updateCCSuccessMessage)
    setLoading(false)
    elements?.getElement(PaymentElement)?.clear()
  }

  const showRetryCard = () => {
    return user.account.state === 'past_due'
  }

  const handleError = (error) => {
    setLoading(false)
    setErrorMessage(error.message)
    setSuccessMessage()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe) {
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    setLoading(true)

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    // Create the SetupIntent and obtain clientSecret
    fetch(`${RemoteConstants.APIEndpoints().ACCOUNTS}setup_payment_method${WebAPIUtils.authGETQueryString()}`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({email: user.email})
    })
    .then(response => {
      return Promise.all([response.status, response.json()])
    })
    .then(([status, json]) => {
      if (status === 200) {
        const responseBody = {...json}
        handleSuccess(responseBody.client_secret)
      } else {
        console.log("Server ERROR")
        const serverError = json.errors.join(". ")
        handleError({message: serverError})
      }
    }).catch((error) => {
      console.log("CAUGHT ERRROR", error)
    })
  }

  const handleSuccess = async (clientSecret) => {
    // Confirm the SetupIntent using the details collected by the Payment Element
    try {
      const {setupIntent, error} = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.href
        }
      })

      if (error) {
        // This point is only reached if there's an immediate error when confirming the setup
        handleError(error)
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        UserActionCreators.updateCC(setupIntent.id)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const retryCurrentCard = async (event) => {
    event.preventDefault()
    setLoading(true)
    UserActionCreators.retryCurrentCC()
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{flexGrow: 1, flexShink: 1, border: '1px solid #ddd', padding: '5px', borderRadius: '6px'}}>
          <PaymentElement />
        </div>
        <Button type="submit" disabled={!stripe || loading} color="primary" style={{height: '40px'}}>
          {loading ? <Spinner className="spinner-button" /> : 'Submit'}
        </Button>
      </form>
      { successMessage && <div className="account-success">{successMessage}</div> }
      { errorMessage && <div className="account-error">{errorMessage}</div> }
      {
        showRetryCard() && <a href="#retry" onClick={retryCurrentCard} style={{display: 'block', textAlign: 'center', marginTop: '20px', fontSize: '1.2rem', fontWeight: 'bold'}}>Or, retry your existing payment method</a>
      }
    </div>
  )
}
