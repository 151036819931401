// import fetch from './fetch'
import queryString from 'query-string'
import ServerActionCreators from './ServerActionCreators'
import SessionStore from '../stores/SessionStore'
import RemoteConstants from '../constants/RemoteConstants'
import AppConstants from '../constants/AppConstants'
import ConnectivityStore from '../stores/ConnectivityStore'

// FIXME: move to superagent to get timeouts back
// NOTE: don't forget to export your new function at the bottom
// FIXME: implement reachability https://medium.com/differential/handling-offline-actions-in-react-native-74949cbfabf2
// FIXME: the error catching is swallowing just about any downstream exception
// FIXME: check 500s vs unreachable

const HEADERS = {'Accept': 'application/json', 'Content-Type': 'application/json'}


function submitLogin(email, password, platform, version) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  console.log("SUBMITTING: ", APIEndpoints.LOGIN);

  fetch(APIEndpoints.LOGIN, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      email: email,
      password: password,
      platform: platform,
      app_version: version
    })
  })
  .then(response => {
    return Promise.all([response, response.status, response.json()])
  })
  .then(([response, status, json]) => {
    // This is different from typical processResponse, since we handle 401 specific to this case
    var errors = null
    switch (status) {
      case 200:
        break
      case 401:
        errors = AppConstants.invalidLoginMessage
        break
      default:
        console.log("SERVER ERROR: ", status)
        errors = AppConstants.loginServerErrorMessage
    }
    ServerActionCreators.loginResponseReceived(response, json, errors)
  })
  .catch((error) => {
    console.log("CAUGHT ERRROR", error)
    ServerActionCreators.loginResponseReceived(null, null, AppConstants.loginServerErrorMessage)
  })
}

function submitForgotPassword(email) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.USERS}forgot_password`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      email: email
    })
  })
  .then(response => {
    return Promise.all([response.status])
  })
  .then(([status]) => {
    var errors = status !== 200
    ServerActionCreators.submitForgotPasswordResponseReceived(errors)
  })
  .catch((error) => {
    console.log("CAUGHT ERRROR", error)
    ServerActionCreators.submitForgotPasswordResponseReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function setNewPassword(token, newPassword) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.USERS}change_password_with_token`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      forgot_password_token: token,
      new_password: newPassword
    })
  })
  .then(response => {
    return Promise.all([response.status])
  })
  .then(([status]) => {
    var errors = status !== 200
    ServerActionCreators.setNewPasswordResponseReceived(errors)
  })
  .catch((error) => {
    console.log("CAUGHT ERRROR", error)
    ServerActionCreators.setNewPasswordResponseReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function refreshUser() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.USER}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.userRefreshReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.userRefreshReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function heartbeat(platform, version) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  let platformString = null
  if (platform) {
    platformString = `&platform=${platform}&app_version=${encodeURIComponent(version)}`
  }

  fetch(`${APIEndpoints.HEARTBEAT}${authGETQueryString()}${platformString}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    // 423 code is what's returned for "no control". Anything else, we're assuming control is allowed
    ServerActionCreators.heartbeatReceived(json, (status !== 423))
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.heartbeatReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadAccountDetails() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}details${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.accountDetailsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.accountDetailsReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadAvailableOfferings() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AVAILABLE_OFFERINGS}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.availableOfferingsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.availableOfferingsReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadPricingForOfferings(offerings, billingInterval) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  var offeringsQS = []
  if (offerings && offerings.length > 0) {
    for (let offering of offerings) {
      // [] encoded is %5B%5D
      offeringsQS.push(`&selected_offerings%5B%5D=${offering}`)
    }
  }
  if (billingInterval) {
    offeringsQS.push(`&billing_interval=${billingInterval}`)
  }
  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}price_for_offerings${authGETQueryString()}${offeringsQS.join('')}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.pricingForOfferingsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.pricingForOfferingsReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function addOfferings(offerings) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}update_offerings${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      selected_offerings: offerings
    })
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.offeringsAdded)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.offeringsAdded(null, AppConstants.connectivityErrorMessage)
  })
}

function loadOfferingsSlate() {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.OFFERING_SLATES}current${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.offeringsSlateReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.offeringsSlateReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function reactivateAccount(offeringSKUs, billingInterval) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  var body = null
  var bodyHash = null
  if (offeringSKUs) {
    bodyHash = {selected_offerings: offeringSKUs}
  }
  if (billingInterval && bodyHash) {
    bodyHash['billing_interval'] = billingInterval
  } else if (billingInterval) {
    bodyHash = {'billing_interval': billingInterval}
  }

  if (bodyHash) {
    body = JSON.stringify(bodyHash)
  }

  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}reactivate${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: body
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.reactivateAccountReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.reactivateAccountReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function updateBillingInterval(billingInterval) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}update_billing_interval${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({billing_interval: billingInterval})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.updateBillingIntervalReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.updateBillingIntervalReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function updateCC(srcID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  const body = JSON.stringify({stripe_source_id: srcID})

  fetch(`${APIEndpoints.ACCOUNTS}update_cc${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: body
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.ccUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.ccUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function retryCurrentCC() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.ACCOUNTS}retry_cc${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.ccRetried)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.ccRetried(null, AppConstants.connectivityErrorMessage)
  })
}

function checkNews() {
  var d = new Date()
  var n = d.toString()
  fetch(`${ConnectivityStore.getNewsJSONUrl()}?ts=${n}`, {
    method: 'GET',
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  })
  .then(response => {
    return Promise.all([response.json()])
  })
  .then(([json]) => {
    ServerActionCreators.newsReceived(json)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.newsReceived(null)
  })
}

function reclaimControl() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.RECLAIM_CONTROL}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status])
  })
  .then(([status]) => {
    ServerActionCreators.reclaimControlReceived((status === 200))
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.reclaimControlReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function relinquishControl() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.RELINQUISH_CONTROL}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
}

function updateUser(params) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  const opts = { method: 'PUT' }

  if (params.avatar) {
    opts.body = Object.entries(params).reduce((fd, [k, v]) => {
      fd.append(`user[${k}]`, v)
      return fd
    }, new FormData())
  } else {
    opts.headers = HEADERS
    opts.body = JSON.stringify(params)
  }

  fetch(`${APIEndpoints.USERS}${authGETQueryString()}`, opts)
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.userUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.userUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function updateRooftop(params) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.ROOFTOPS}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.rooftopUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.rooftopUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function updateUserAuctionFilters(params) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.USERS}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.userAuctionFiltersUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.userAuctionFiltersUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function addUser(email, userType) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}create_additional_user${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      additional_user: [email, userType]
    })
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.userAdded)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.userAdded(null, AppConstants.connectivityErrorMessage)
  })
}

function loadAdditionalUserPricing() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.ACCOUNT_MANAGEMENT}additional_user_pricing${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.additionalUserPricingReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.additionalUserPricingReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function fetchCarfaxAuthorizeUrl(redirectUrl) {
  const redirectUrlParam = `redirect_url=${encodeURIComponent(redirectUrl)}`
  fetch(`${RemoteConstants.APIEndpoints().CARFAX_CONNECT_AUTHORIZE_URL}${authGETQueryString()}&${redirectUrlParam}`)
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.carfaxConnectAuthorizeUrlRecieved)
  })
  .catch((error) => {
    console.log(error)
  })
}

function logoutCarfaxConnect() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.CARFAX_CONNECT_LOGOUT}${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.carfaxConnectLoggedOut)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.carfaxConnectLoggedOut(null, AppConstants.connectivityErrorMessage)
  })
}

function urlForAutocheckReport(vehicleUUID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  return `${APIEndpoints.VEHICLES}${vehicleUUID}/history_report/${authGETQueryString()}&provider=autocheck`
}

function loadMileageFamily(vehicleUUID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.VEHICLES}${vehicleUUID}/mileage_family${authGETQueryString()}&provider=autocheck`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.mileageFamilyReceived, vehicleUUID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.mileageFamilyReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function updateAutoCheckCredentials(autoCheckUsername) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.USERS}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({autocheck_sid: autoCheckUsername})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.autoCheckCredentialsUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.autoCheckCredentialsUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function removeAutoCheckCredentials() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.USERS}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({autocheck_sid: null})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.autoCheckCredentialsRemoved)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.autoCheckCredentialsRemoved(null, AppConstants.connectivityErrorMessage)
  })
}

function loadExistingVehicle(vehicleUUID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.SHOW_VALUATION}${vehicleUUID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.existingValuationReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.existingValuationReceived(null, AppConstants.connectivityErrorMessage, {clientID: clientID})
  })
}

function createVINValuation(vin, mileage, overallCondition, zipCode, selectedValuations, clientID, vinEntryType, additionalParams, folderID) {
  var params = {
    vin: vin,
    mileage: mileage,
    overall_condition: overallCondition,
    zip_code: zipCode,
    selected_valuations: selectedValuations,
    vin_entry_type: vinEntryType,
    vehicle_folder_id: folderID
  }
  if (additionalParams) {
    params = {...params, ...additionalParams}
  }
  baseValuationCreate(params, clientID)
}

function publicVINValuation(vin, mileage, zipCode, choices = null) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  var params = {
    vin: vin, mileage: mileage, zip_code: zipCode
  }

  if (choices) {
    params['selected_valuations'] = choices
  }

  fetch(`${APIEndpoints.VALUATIONS}show_public`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.publicVINValuationReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.publicVINValuationReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function createParamsValuation(params, selectedValuations, clientID) {
  baseValuationCreate({
    year: params.year,
    make: params.make,
    model: params.model,
    series: params.series,
    style: params.style,
    mileage: params.mileage,
    overall_condition: params.overallCondition,
    zip_code: params.zipCode,
    selected_valuations: selectedValuations,
    vehicle_folder_id: params.vehicle_folder_id
  },
  clientID)
}

function loadVINValuationPreview(vin, mileage, zipCode, clientID) {
  baseValuationPreviewLoad({
    vin: vin,
    mileage: mileage,
    zip_code: zipCode
  },
  clientID)
}

function loadParamsValuationPreview(params, clientID) {
  baseValuationPreviewLoad({
    year: params.year,
    make: params.make,
    model: params.model,
    series: params.series,
    style: params.style,
    mileage: params.mileage,
    zip_code: params.zipCode
  },
  clientID)
}

function baseValuationPreviewLoad(body, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(APIEndpoints.VALUATION_PREVIEW, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(Object.assign(body, authParams()))
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationPreviewReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationPreviewReceived(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadPreviewForVehicle(vehicleUUID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}/valuation_preview/${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.previewForVehicleReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.previewForVehicleReceived(null, AppConstants.connectivityErrorMessage, vehicleUUID)
  })

}

function baseValuationCreate(body, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(APIEndpoints.VALUATIONS, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(Object.assign(body, authParams()))
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationReceived, {isNew: true, clientID: clientID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationReceived(null, AppConstants.connectivityErrorMessage, {clientID: clientID})
  })
}

function updateValuation(vehicleUUID, params, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}${authGETQueryString()}`, {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationUpdated, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationUpdated(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function updateValuationChoice(vehicleUUID, providerKey, choiceUID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  var providerHash = {}
  providerHash[providerKey] = choiceUID

  var params = {}
  params['updated_selected_valuations'] = providerHash

  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}${authGETQueryString()}`, {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationChoiceUpdated, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationChoiceUpdated(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadLimitedProvidersValuation(vehicleUUID, params, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}/limited_valuation_provider${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationUpdated, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationPreviewReceived(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function deleteValuation(vehicleUUID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}${authGETQueryString()}`, {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify({ state: 'archived'})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationDeleted, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationDeleted(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function linkValuationOnPartner(vehicleUUID, partnerCode, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}/create_on_partner${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({ partnerCode: partnerCode})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationLinkedToPartner, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationLinkedToPartner(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadCarfaxSummary(vehicleUUID, purchase) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.VEHICLES}${vehicleUUID}/reports/carfax/${authGETQueryString()}&should_purchase=${purchase === true ? 'Y' : 'N'}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.carfaxSummaryRecieved, vehicleUUID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.carfaxSummaryRecieved(null, AppConstants.connectivityErrorMessage, vehicleUUID)
  })
}

function loadVintelReport(vehicleUUID, reportDetail) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.VEHICLES}${vehicleUUID}/reports/vintel/${authGETQueryString()}&report_detail=${reportDetail}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vintelReportReceived, vehicleUUID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vintelReportReceived(null, AppConstants.connectivityErrorMessage, vehicleUUID)
  })
}

function loadAutoCheckReport(vehicleUUID, reportDetail) {
  const APIEndpoints = RemoteConstants.APIEndpoints()
  const serverActionCreator = reportDetail === 'summary' ? ServerActionCreators.autoCheckSummaryRecieved : ServerActionCreators.autoCheckReportReceived
  fetch(`${APIEndpoints.VEHICLES}${vehicleUUID}/reports/autocheck/${authGETQueryString()}&report_detail=${reportDetail}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([
      response.status,
      reportDetail === 'summary' ? response.json() : response.text(),
    ])
  })
  .then(([status, json]) => {
    processResponse(status, json, serverActionCreator, vehicleUUID)
  })
  .catch((error) => {
    console.log(error)
    serverActionCreator(null, AppConstants.connectivityErrorMessage, vehicleUUID)
  })
}

function createValuationNote(vehicleUUID, noteText, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}/vehicle_notes${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({note: noteText})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationNoteCreated, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationNoteCreated(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadValuationNotes(vehicleUUID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}/vehicle_notes/${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vehicleNotesRecieved, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vehicleNotesRecieved(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadVehiclesHome(withFolderVehicles = false, scope, order) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  let queryStr = `${authGETQueryString()}${userScopeQueryString(scope)}&with_folder_vehicles=${withFolderVehicles}${orderQueryString(order)}`

  fetch(`${APIEndpoints.VEHICLES_HOME}${queryStr}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vehiclesHomeReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vehiclesHomeReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadPMRSampleDetail(uuid, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.PMR_SAMPLE_DETAIL}${uuid}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.pmrSampleDetailReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.pmrSampleDetailReceived(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadAllPMRSamples(uuid, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.PMR_SAMPLES}${uuid}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.pmrAllSamplesReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.pmrAllSamplesReceived(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function createFolder(name) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.FOLDERS}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      name: name
    })
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.folderCreated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.folderCreated(null, error, AppConstants.connectivityErrorMessage)
  })
}

function updateFolder(folderID, name) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.FOLDERS}${folderID}/${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify({
      name: name
    })
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.folderUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.folderUpdated(null, error, AppConstants.connectivityErrorMessage)
  })
}

function updateFoldersOrder(foldersOrder) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.FOLDERS_ORDER}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({
      order: foldersOrder
    })
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.foldersOrderUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.foldersOrderUpdated(null, error, AppConstants.connectivityErrorMessage)
  })
}

function deleteFolder(folderID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.FOLDERS}${folderID}/${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'DELETE',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.folderDeleted)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.folderDeleted(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadFolderInfo(folderID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.FOLDERS}${folderID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.loadFolderInfoReceived, {clientID: clientID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.loadFolderInfoReceived(null, error, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadFolders() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.FOLDERS}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.foldersReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.foldersReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

// Legacy for folder use.  We now have a folder endpoint
function loadRecentValuations(cutoff, folderID, clientID, scope, order) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  const qs = `${authGETQueryString()}${userScopeQueryString(scope)}${cutoffAndOrderQueryString(cutoff, order)}`

  const baseURL = folderID && folderID !== AppConstants.recentValuationsListClientID ?
    `${APIEndpoints.FOLDERS}${folderID}/vehicles` :
    `${APIEndpoints.RECENT_VALUATIONS}`

  fetch(`${baseURL}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.recentValuationsReceived, {order, listResponseType: RemoteConstants.ListResponseTypes[cutoff ? 'CUTOFF' : 'ALL'], clientID: clientID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.recentValuationsReceived(null, error, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadValuationsCorpus(scope) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VALUATIONS_CORPUS}${authGETQueryString()}${userScopeQueryString(scope)}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationsCorpusReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationsCorpusReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function searchValuations(cutoff, params, scope, order) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  const paramStrings = Object.keys(params).map(k => encodeURI(`${k}=${params[k]}`)).join('&')
  const qs = `${cutoffAndOrderQueryString(cutoff, order)}${userScopeQueryString(scope)}`

  fetch(`${APIEndpoints.VALUATIONS}${authGETQueryString()}&${paramStrings}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationsSearchReceived, {order, listResponseType: RemoteConstants.ListResponseTypes[cutoff ? 'CUTOFF' : 'ALL']})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationsCorpusReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function updateValuationChoices(vehicleUUID, choices) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VEHICLES}${vehicleUUID}/update_selected_valuations${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({selected_valuations: choices})
  })
  .then(() => {
    ServerActionCreators.vehicleChoicesUpdated()
  })
  .catch((error) => {
    console.log(error)
  })
}

function loadAvailableYears() {
  console.log("WebAPIUtils loadAvailableYears")

  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AVAILABLE_YEARS}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.availableYearsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.availableYearsReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadAvailableMakesModels(year) {
  console.log("WebAPIUtils loadAvailableMakesModels")

  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AVAILABLE_MAKES_MODELS}${year}${authGETQueryString()}`, {
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.availableMakesModelsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.availableMakesModelsReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function searchAuctionVehicles(page, params, clientID, queryID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTIONS_SEARCH}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({...params, page: page, limit: params?.limit || 50})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionVehiclesSearchReceived, {page: page, clientID: clientID, queryID: queryID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionVehiclesSearchReceived(null, error, AppConstants.connectivityErrorMessage, {page: page, clientID: clientID, queryID: queryID})
  })
}

function loadAuctionsByState() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTIONS_BY_STATE}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionsByStateReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionsByStateReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadAuctionListing(listingID, storeWithSourceID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTION_LISTING}${listingID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionListingReceived, {storeWithSourceID: storeWithSourceID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionListingReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function prefetchAuctionListing(listingID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTION_LISTING}${listingID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionListingPrefetchReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionListingPrefetchReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadNextAuctionSales() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTION_NEXT_SALES}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.nextAuctionLanesReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.nextAuctionLanesReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadAuctionListingsForLane(saleID, laneID, clientID, afterRunNumber) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  var runNumberParam = ''
  if (afterRunNumber !== null && afterRunNumber !== undefined) { runNumberParam = '&run_number='+afterRunNumber }

  const encodedLaneID = encodeURIComponent(laneID)

  // Prev / next navigation for this list requires the full list, very large page size to prevent pagination
  fetch(`${APIEndpoints.AUCTIONS_SALES}${saleID}/listings${authGETQueryString()}&lane=${encodedLaneID}${runNumberParam}&limit=1000`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionListingForLaneReceived, {clientID: clientID, afterRunNumber: afterRunNumber})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionListingForLaneReceived(null, AppConstants.connectivityErrorMessage, {clientID: clientID, afterRunNumber: afterRunNumber})
  })
}

function loadAuctionListingsForSale(saleID, pageSize, clientID, offset) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTIONS_SALES}${saleID}/listings${authGETQueryString()}&offset=${offset}&limit=${pageSize}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionListingForSaleReceived, {clientID: clientID, offset: offset})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionListingForSaleReceived(null, AppConstants.connectivityErrorMessage, {clientID: clientID, offset: offset})
  })
}

function updateAuctionListing(listingID, params) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTION_LISTING}${listingID}${authGETQueryString()}`, {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(() => {
    ServerActionCreators.auctionListingUpdated()
  })
  .catch((error) => {
    console.log("CAUGHT ERROR from WebAPIUtils.updateAuctionListing", error)
    // FIXME: do something?
  })
}

function loadAuctionsWatchlistVehicles(scope) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  const qs = `${authGETQueryString()}${userScopeQueryString(scope)}`

  // Prev / next navigation for this list requires the full list, very large page size to prevent pagination
  fetch(`${APIEndpoints.AUCTIONS_WATCHLIST}${authGETQueryString()}${qs}&list_type=active&limit=1000`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionsWatchlistReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionsWatchlistReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadAuctionsWatchlistArchiveVehicles() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  // Prev / next navigation for this list requires the full list, very large page size to prevent pagination
  fetch(`${APIEndpoints.AUCTIONS_WATCHLIST}${authGETQueryString()}&list_type=dormant&limit=1000`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionsWatchlistArchiveReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionsWatchlistArchiveReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadAuctionsWatchlistVehicleCounts() {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.AUCTIONS_WATCHLIST}details${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionsWatchlistCountsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionsWatchlistCountsReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function watchlistVIN(vin, listingID, addWatchlist, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  const params = addWatchlist ? {vin: vin, auction_listing_id: listingID} : {vin: vin}

  fetch(`${APIEndpoints.AUCTIONS_WATCHLIST}${authGETQueryString()}`, {
    method: addWatchlist ? 'POST' : 'DELETE',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vinWatchlisted, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vinWatchlisted(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadSavedSearches() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.SAVED_SEARCHES}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.savedSearchesReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.savedSearchesReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadSavedSearch(searchUUID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.SAVED_SEARCHES}${searchUUID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.savedSearchReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.savedSearchReceived(null, error, AppConstants.connectivityErrorMessage, clientID)
  })
}

function updateSavedSearch(searchUUID, params, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.SAVED_SEARCHES}${searchUUID}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.savedSearchReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.savedSearchReceived(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function updateGlobalSavedSearch(searchUUID, params) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.SAVED_SEARCHES}${searchUUID}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.globalSavedSearchUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.globalSavedSearchUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function createSavedSearch(params, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.SAVED_SEARCHES}${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.savedSearchReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.savedSearchReceived(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function deleteSavedSearch(searchUUID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.SAVED_SEARCHES}${searchUUID}${authGETQueryString()}`, {
    method: 'DELETE',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status]) => {
    processResponse(status, null, ServerActionCreators.savedSearchDeleted)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.savedSearchDeleted(null, AppConstants.connectivityErrorMessage)
  })
}

function checkAuctionsForVIN(vin, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTIONS_FOR_VIN}${vin}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionsForVINReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionsForVINReceived(null, error, AppConstants.connectivityErrorMessage, clientID)
  })
}

function createValuationShare(vehicleUUID, params, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VEHICLES}${vehicleUUID}/vehicle_shares${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.valuationShareCreated, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.valuationShareCreated(null, AppConstants.connectivityErrorMessage, clientID)
  })
}

function loadSharedValuation(uuid) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  // fetch(`${APIEndpoints.SHOW_SHARED_VALUATION}21`, {
  fetch(`${APIEndpoints.VEHICLE_SHARES}${uuid}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    switch (response.status) {
      case 200:
      case 201:
      case 202:
        return Promise.all([response.status, response.json(), null])
      default:
        return Promise.all([response.status, null, null])

    }
  })
  .then(([status, json]) => {
    switch (status) {
      case 200:
      case 201:
      case 202:
        ServerActionCreators.sharedValuationReceived(json, null)
        break
      case 404:
        ServerActionCreators.sharedValuationReceived(null, 'Report not found')
        break
      case 410:
        ServerActionCreators.sharedValuationReceived(null, 'Report no longer available.  Contact the person who sent the report for a new link.')
        break
      default:
        ServerActionCreators.sharedValuationReceived(null, null)

    }

  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.sharedValuationReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function uploadVehiclePhoto(data, isPrimary, vehicleUUID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  return new Promise((resolve, reject) => {
    fetch(`${APIEndpoints.VALUATIONS}${vehicleUUID}/vehicle_photos${authGETQueryString()}`, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({
        attachment: data,
        primary: isPrimary && isPrimary === true ? true : false
      })
    })
    .then(response => {
      return Promise.all([response.status, response.json()])
    })
    .then(([status, json]) => {
      if (status === 201) {
        resolve(json)
        processResponse(status, json, ServerActionCreators.photoUpdated, clientID)
      } else {
        reject()
      }
    })
    .catch((error) => {
      console.log(error)
      reject(error)
    })
  })
}

function updatePhoto(photoID, params, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VEHICLE_PHOTOS}${photoID}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.photoUpdated, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.photoUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function deletePhoto(photoID, clientID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VEHICLE_PHOTOS}${photoID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'DELETE',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status])
  })
  .then(([status]) => {
    processResponse(status, null, ServerActionCreators.photoDeleted, {photoID: photoID, clientID: clientID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.photoDeleted(null, AppConstants.connectivityErrorMessage)
  })
}

function authGETQueryString() {
  const ap = authParams()
  const encodedUID = encodeURIComponent(ap.uid)
  return `?access-token=${ap['access-token']}&uid=${encodedUID}&client=${ap.client}`
}

function loadVehicleActivities(cutoff, scope, clientID, order) {
  let qs = `${authGETQueryString()}${cutoffAndOrderQueryString(cutoff, order)}`
  if (scope) {
    qs += `&${queryString.stringify(scope)}`
  }

  fetch(`${RemoteConstants.APIEndpoints().VEHICLE_ACTIVITIES}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vehicleActivitiesReceived, {
      order,
      clientID,
      listResponseType: RemoteConstants.ListResponseTypes[cutoff ? 'CUTOFF' : 'ALL'],
    })
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vehicleActivitiesReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadLLMSampleDetail(sampleID, clientID) {
  fetch(`${RemoteConstants.APIEndpoints().LLM_SAMPLE_DETAIL}${sampleID}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.llmSampleDetailReceived, {clientID: clientID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.llmSampleDetailReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadLLMTrimSamples(trimID, distance, vehicleUUID, clientID) {
  let qs = `${authGETQueryString()}&vehicle_uuid=${vehicleUUID}&distance=${distance}`
  fetch(`${RemoteConstants.APIEndpoints().LLM_TRIM_SAMPLES}${trimID}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.llmTrimSamplesReceived, {clientID: clientID})
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.llmTrimSamplesReceived(null, AppConstants.connectivityErrorMessage)
  })
}

async function loadMyLotVehicles({ pageStart = 1, pageEnd: pageEndParam, order, clientID, query, limit }) {
  // Supports ranges of pages. Accumulates pages with parallel requests before dispatching
  // to enable components to refetch lists without losing scroll position.
  const pageEnd = pageEndParam || pageStart
  const actionCreator = ServerActionCreators.myLotVehiclesReceived
  const url = new URL(RemoteConstants.APIEndpoints().MY_LOT_VEHICLES + authGETQueryString())
  const params = url.searchParams
  if (order) params.set('order', order)
  if (limit) params.set('limit', limit)

  const fetches = []
  let hasError = false

  for (let page = pageStart; page <= pageEnd; page++) {
    params.set('page', page)
    const resp = fetch(url.toString(), {
      timeout: AppConstants.serverTimeoutPeriod,
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify(query)
    })
    fetches.push(resp)
  }

  const result = await fetches.reduce(async (accumPromise, promise) => {
    const accum = await accumPromise
    if (hasError) return accum
    const response = await promise
    try {
      const next = await Promise.all([response.status, response.json()])
      return [...accum, next]
    } catch (e) {
      console.log(error)
      hasError = true
    }
  }, Promise.resolve([]))

  if (hasError) {
    actionCreator(null, AppConstants.connectivityErrorMessage, { clientID })
  } else {
    result.forEach(([status, json], i) => {
      processResponse(status, json, actionCreator, { page: pageStart + i, clientID })
    })
  }
}

function loadMyLotVehiclesDetails() {
  const qs = `${authGETQueryString()}`
  const actionCreator = ServerActionCreators.myLotVehiclesDetailsReceived

  fetch(`${RemoteConstants.APIEndpoints().MY_LOT_VEHICLES_DETAILS}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, actionCreator)
  })
  .catch((error) => {
    console.log(error)
    actionCreator(null, AppConstants.connectivityErrorMessage)
  })
}

function loadMyLotReport() {
  fetch(`${RemoteConstants.APIEndpoints().MY_LOT_REPORT}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.myLotReportReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.myLotReportReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadMyLotCompetitorsReport({ radius, dealerType, zip, clientID }) {
  let qs = authGETQueryString()
  if (radius) qs += `&radius=${radius}`
  if (zip) qs += `&zip=${zip}`
  if (dealerType) qs += `&business_type=${dealerType}`

  const actionCreator = ServerActionCreators.myLotCompetitorsReportReceived

  fetch(`${RemoteConstants.APIEndpoints().MY_LOT_COMPETITORS_REPORT}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, actionCreator, { clientID })
  })
  .catch((error) => {
    console.log(error)
    actionCreator(null, AppConstants.connectivityErrorMessage, { clientID })
  })
}

function updateLotVehicle(params, clientID) {
  let qs = authGETQueryString()
  fetch(`${RemoteConstants.APIEndpoints().MY_LOT_VEHICLES}${qs}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.lotVehicleUpdated, { clientID, params })
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.lotVehicleUpdated(null, AppConstants.connectivityErrorMessage, { clientID })
  })
}

function deleteLotVehicle (vehicleUUID, clientID) {
  let qs = authGETQueryString()
  fetch(`${RemoteConstants.APIEndpoints().MY_LOT_VEHICLES}${qs}`, {
    method: 'DELETE',
    headers: HEADERS,
    body: JSON.stringify({uuid: vehicleUUID})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.lotVehicleDeleted, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.lotVehicleDeleted(null, AppConstants.connectivityErrorMessage)
  })
}

function loadRegionalReport(zip, radius) {
  let qs = `${authGETQueryString()}&radius=${radius}`
  if (zip) qs += `&zip=${zip}`
  const actionCreator = ServerActionCreators.regionalReportReceived

  fetch(`${RemoteConstants.APIEndpoints().REGIONAL_REPORT}${qs}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, actionCreator, { zip, radius })
  })
  .catch((error) => {
    console.log(error)
    actionCreator(null, AppConstants.connectivityErrorMessage)
  })
}

function loadRegionalListings({ year, make, model, trim, bodyStyle, zip, radius, clientID, page, order }) {
  const url = new URL(RemoteConstants.APIEndpoints().REGIONAL_LISTINGS + authGETQueryString())
  const params = url.searchParams
  params.set('zip', zip)
  params.set('radius', radius)
  if (model) params.set('model', model)
  if (year) params.set('year', year)
  if (trim) params.set('trim', trim)
  if (make) params.set('make', make)
  if (bodyStyle) params.set('body_style', bodyStyle)
  if (page) params.set('page', page)
  if (order) params.set('order', order)
  const actionCreator = ServerActionCreators.regionalListingsReceived

  fetch(url, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, actionCreator, { page, clientID })
  })
  .catch((error) => {
    console.log(error)
    actionCreator(null, AppConstants.connectivityErrorMessage, { page, clientID})
  })
}

function loadRegionalModelStats({ make, model, zip, radius, endDate, clientID, includeUngrouped, includeGrouped }) {
  const url = new URL(RemoteConstants.APIEndpoints().REGIONAL_MODEL_STATS + authGETQueryString())
  const params = url.searchParams

  params.set('make', make)
  params.set('model', model)
  params.set('zip', zip)
  params.set('radius', radius)
  params.set('include_ungrouped', includeUngrouped === true ? 'Y' : 'N')
  params.set('include_grouped', includeGrouped === true ? 'Y' : 'N')
  if (endDate) params.set('endDate', endDate)

  const actionCreator = ServerActionCreators.regionalModelStatsReceived

  fetch(url, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, actionCreator, { clientID })
  })
  .catch((error) => {
    console.log(error)
    actionCreator(null, AppConstants.connectivityErrorMessage)
  })
}

function loadRegionalModelScorecard({ year, make, model, trim, zip, radius, endDate, clientID }) {
  const url = new URL(RemoteConstants.APIEndpoints().REGIONAL_MODEL_SCORECARD + authGETQueryString())
  const params = url.searchParams

  params.set('make', make)
  params.set('model', model)
  params.set('zip', zip)
  if (radius) params.set('radius', radius)
  if (year) params.set('year', year)
  if (trim) params.set('trim', trim)
  if (endDate) params.set('endDate', endDate)

  const actionCreator = ServerActionCreators.regionalModelScorecardReceived

  fetch(url, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, actionCreator, { clientID })
  })
  .catch((error) => {
    console.log(error)
    actionCreator(null, AppConstants.connectivityErrorMessage)
  })
}

function loadMostRecentRetailListing(vin) {
  fetch(`${RemoteConstants.APIEndpoints().RETAIL_LISTING}${vin}/most_recent_listing${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.mostRecentRetailListingReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.mostRecentRetailListingReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function toggleUserGlobalAuctionFilters(enabled) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.USERS}${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify({'global_listing_search_enabled': enabled})
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.toggleUserGlobalAuctionFiltersUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.toggleUserGlobalAuctionFiltersUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function getCoordinatesForZipCode(zipCode) {
  fetch(`${RemoteConstants.APIEndpoints().UTILITIES}geocode_from_zip/${zipCode}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.coordinatesForZipCodeReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.coordinatesForZipCodeReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function loadVehicleReport(vehicleUUID, reportType, clientID) {
  fetch(`${RemoteConstants.APIEndpoints().VEHICLES}${vehicleUUID}/reports/${reportType}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vehicleReportReceived, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vehicleReportReceived(null, AppConstants.connectivityErrorMessage)
  })
}

function purchaseVehicleReport(vehicleUUID, reportType, clientID) {
  fetch(`${RemoteConstants.APIEndpoints().VEHICLES}${vehicleUUID}/reports/${reportType}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'POST',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vehicleReportPurchased, clientID)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vehicleReportPurchased(null, AppConstants.connectivityErrorMessage)
  })
}

function updatePermissions(permissions) {
  fetch(`${RemoteConstants.APIEndpoints().ACCOUNT_MANAGEMENT}update_permissions${authGETQueryString()}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(permissions)
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.permissionsUpdated)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.permissionsUpdated(null, AppConstants.connectivityErrorMessage)
  })
}

function loadEdgeAuthorizeUrl(redirectURL, parametersString) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  const redirectURLParam = `redirect_url=${encodeURIComponent(redirectURL)}${parametersString ? parametersString : ''}`

  fetch(`${APIEndpoints.EDGE_AUTHORIZE_URL}${authGETQueryString()}&${redirectURLParam}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.edgeAuthorizeURLReceieved)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.edgeAuthorizeURLReceieved(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadDotAuctionEvents(page, count) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.DOT_AUCTION_EVENTS}${authGETQueryString()}&page=${page}&count=${count}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.dotAuctionEventsReceived, page)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.dotAuctionEventsReceived(null, error, AppConstants.connectivityErrorMessage, page)
  })
}

function loadAuctionLiveSalesStatus(page) {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.AUCTION_LISTING}live_bidding_status${authGETQueryString()}&page=${page}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionLiveSalesStatusReceived, page)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionLiveSalesStatusReceived(null, error, AppConstants.connectivityErrorMessage, page)
  })
}

function refreshAuctionEdgeToken() {
  // Special endpoint that refreshes auction edge token and returns serialized user.
  // Consumed outside of flux system.
  const APIEndpoints = RemoteConstants.APIEndpoints()

  return fetch(`${APIEndpoints.REFRESH_AUCTION_EDGE_TOKEN}${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.userRefreshReceived)
    return [json, status]
  })
  .catch((error) => {
    console.log(error)
  })
}

function loadAuctionListingBids() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.USERS}listing_bids${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.auctionListingBidsReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.auctionListingBidsReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function logoutAuctionEdge() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.LOGOUT_AUCTION_EDGE}${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.logoutAuctionEdgeReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.logoutAuctionEdgeReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function submitListingBidAudit(params) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.LISTING_BID_AUDITS}${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(params)
  })
  .then(response => {
    return response.status
  })
  .catch((error) => {
    console.log(error)
  })
}

function createMetric(key, value, metadata) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.METRICS}${authGETQueryString()}`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({key: key, value: value, metadata: metadata})
  })
  .then(response => {
    // We don't care about a response here
    return response.status
  })
  .catch((error) => {
    console.log(error)
  })
}

function loadVDSBaseCorpus() {
  let APIEndpoints = RemoteConstants.APIEndpoints()

  fetch(`${APIEndpoints.VDS_CORPUS}all${authGETQueryString()}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vdsBaseCorpusReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vdsBaseCorpusReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loadVDSMakesCorpus(makes) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  var paramsQS = []

  for (let make of makes) {
    // [] encoded is %5B%5D
    paramsQS.push(`&makes%5B%5D=${make}`)
  }

  fetch(`${APIEndpoints.VDS_CORPUS}model_trims${authGETQueryString()}&${paramsQS.join('')}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.vdsMakesCorpusReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vdsMakesCorpusReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

function loginWithApolloCampaignContactID(apolloCampaignContactID) {
  let APIEndpoints = RemoteConstants.APIEndpoints()
  fetch(`${APIEndpoints.APOLLO_CAMPAIGN_LOGIN}${authGETQueryString()}&campaign_contact_email_id=${apolloCampaignContactID}`, {
    timeout: AppConstants.serverTimeoutPeriod,
    method: 'GET',
    headers: HEADERS
  })
  .then(response => {
    return Promise.all([response.status, response.json()])
  })
  .then(([status, json]) => {
    processResponse(status, json, ServerActionCreators.loginWithApolloCampaignContactIDReceived)
  })
  .catch((error) => {
    console.log(error)
    ServerActionCreators.vdsBaseCorpusReceived(null, error, AppConstants.connectivityErrorMessage)
  })
}

export default {
  submitLogin,
  submitForgotPassword,
  setNewPassword,
  refreshUser,
  heartbeat,
  loadAccountDetails,
  loadAvailableOfferings,
  loadPricingForOfferings,
  addOfferings,
  loadOfferingsSlate,
  reactivateAccount,
  updateBillingInterval,
  updateCC,
  retryCurrentCC,
  checkNews,
  reclaimControl,
  relinquishControl,
  updateUser,
  updateRooftop,
  updateUserAuctionFilters,
  addUser,
  loadAdditionalUserPricing,
  loadAutoCheckReport,
  urlForAutocheckReport,
  loadMileageFamily,
  updateAutoCheckCredentials,
  removeAutoCheckCredentials,
  loadExistingVehicle,
  loadVINValuationPreview,
  loadParamsValuationPreview,
  loadPreviewForVehicle,
  createVINValuation,
  publicVINValuation,
  createParamsValuation,
  updateValuation,
  updateValuationChoice,
  loadLimitedProvidersValuation,
  deleteValuation,
  linkValuationOnPartner,
  loadVehiclesHome,
  loadPMRSampleDetail,
  loadAllPMRSamples,
  createFolder,
  updateFolder,
  updateFoldersOrder,
  deleteFolder,
  loadFolderInfo,
  loadFolders,
  loadRecentValuations,
  loadValuationsCorpus,
  searchValuations,
  loadCarfaxSummary,
  loadAutoCheckReport,
  createValuationNote,
  loadValuationNotes,
  updateValuationChoices,
  loadAvailableYears,
  loadAvailableMakesModels,
  searchAuctionVehicles,
  loadAuctionsByState,
  loadAuctionListing,
  prefetchAuctionListing,
  loadNextAuctionSales,
  loadAuctionListingsForLane,
  loadAuctionListingsForSale,
  updateAuctionListing,
  loadAuctionsWatchlistVehicles,
  loadAuctionsWatchlistArchiveVehicles,
  loadAuctionsWatchlistVehicleCounts,
  watchlistVIN,
  loadSavedSearches,
  loadSavedSearch,
  updateSavedSearch,
  updateGlobalSavedSearch,
  createSavedSearch,
  deleteSavedSearch,
  checkAuctionsForVIN,
  createValuationShare,
  loadSharedValuation,
  uploadVehiclePhoto,
  deletePhoto,
  updatePhoto,
  fetchCarfaxAuthorizeUrl,
  logoutCarfaxConnect,
  loadVehicleActivities,
  loadLLMSampleDetail,
  loadLLMTrimSamples,
  loadMyLotVehicles,
  loadMyLotVehiclesDetails,
  loadMyLotReport,
  loadMyLotCompetitorsReport,
  updateLotVehicle,
  deleteLotVehicle,
  loadMostRecentRetailListing,
  toggleUserGlobalAuctionFilters,
  getCoordinatesForZipCode,
  loadVehicleReport,
  purchaseVehicleReport,
  updatePermissions,
  loadEdgeAuthorizeUrl,
  loadDotAuctionEvents,
  loadAuctionLiveSalesStatus,
  refreshAuctionEdgeToken,
  loadAuctionListingBids,
  logoutAuctionEdge,
  submitListingBidAudit,
  createMetric,
  loadRegionalReport,
  loadRegionalListings,
  loadRegionalModelStats,
  loadVDSBaseCorpus,
  loadVDSMakesCorpus,
  loadVintelReport,
  loginWithApolloCampaignContactID,
  loadRegionalModelScorecard,
  authGETQueryString,
}

function authParams() {
  return SessionStore.authParams()
}

function cutoffAndOrderQueryString(cutoff, order) {
  if (cutoff && !order) throw 'cutoff param provided without order param!';
  return [
    order && `&order=${order}`,
    cutoff && `&cutoff=${cutoff}`,
  ].filter(Boolean).join('')
}

function userScopeQueryString(scope) {
  return (scope != null && scope !== RemoteConstants.vehicleScopeMine) ?
    `&user=${scope}` :
    ''
}

function orderQueryString(order) {
  return order ? `&order=${order}` : ''
}

function processResponse(status, response, onSuccess, successParameters) {
  switch (status) {
    case 200:
    case 201:
    case 202:
      onSuccess(response, null, successParameters)
      break
    case 401:
      ServerActionCreators.unauthorizedUserReceived()
      break
    case 403:
      onSuccess(null, AppConstants.server403Message, successParameters)
      break
    case 404:
      onSuccess(null, AppConstants.server404Message, successParameters)
      break
    case 412:
      ServerActionCreators.invalidAPIVersion()
      break
    case 422:
      onSuccess(response, AppConstants.server422Message, successParameters)
      break
    case 555:
      ServerActionCreators.serverMaintenanceMode(response)
      break
    default:
      console.log("SERVER ERROR: ", status)
      onSuccess(null, AppConstants.serverErrorMessage, successParameters)
  }
}
