import React from 'react'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import SessionStore from '../../api/stores/SessionStore'
import UserActionCreators from '../../api/actions/UserActionCreators'
import PaymentFormModal from '../settings/PaymentFormModal'
import ProviderLogo from './icons/providers/ProviderLogo'
import Formatters from '../../api/constants/Formatters'
import CheckCircleIcon from './icons/CheckCircleIcon'
import Spinner from './Spinner'
import platformFunctions from '../../platformFunctions'
import CommonFunctions from '../../api/constants/CommonFunctions'
import AppConstants from '../../api/constants/AppConstants'
import pluralize from 'pluralize'

const CARBLY_PLUS_BUNDLE_SKU = AppConstants.skuMappings['carbly_plus_bundle']

export default class OfferingsPicker extends React.PureComponent {
  state = {
    selectedOfferings: [],
    isLoadingCostForSelected: false,
    costForSelected: null,
    isAddingOfferings: false,
    isAddOfferingsComplete: false,
    addOfferingErrors: null,
    isShowingAddPaymentModal: false,
    pricingForOfferingsErrors: null
  }

  constructor(props) {
    super(props)
    this.carblyPlusBundleOfferings = props.offerings.find((offering) => offering.sku === CARBLY_PLUS_BUNDLE_SKU)?.replaced_skus || []
  }

  componentDidMount() {
    SessionStore.on("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.on("offerings_added", this.offeringsAdded)
    SessionStore.on("user_data_changed", this.userDataChanged)
  }

  componentWillUnmount() {
    SessionStore.removeListener("pricing_for_offerings_received", this.costForSelectedOfferingsReceived)
    SessionStore.removeListener("offerings_added", this.offeringsAdded)
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
  }

  userDataChanged = () => {
    const user = SessionStore.user
    if (this.state.isShowingAddPaymentModal) {
      // Free user just successfully added a payment method
      // FIXME: check that there is payment?
      this.setState({
        isShowingAddPaymentModal: false
      })
      this.addOfferings()
    } else {
      this.setState({ user: SessionStore.user })
    }

  }

  costForSelectedOfferingsReceived = () => {
    const pricingForOfferings = SessionStore.pricingForOfferings
    const pricingForOfferingsErrors = SessionStore.pricingForOfferingsErrors

    this.setState({
      isLoadingCostForSelected: false,
      costForSelected: pricingForOfferings,
      pricingForOfferingsErrors: pricingForOfferingsErrors
    })

    if (this.props.onSelectedOfferingsChange) {
      this.props.onSelectedOfferingsChange(this.state.selectedOfferings, pricingForOfferings)
    }
    if (this.props.onCostForSelectedOfferingsLoadingStateChange) {
      this.props.onCostForSelectedOfferingsLoadingStateChange(false, Boolean(pricingForOfferingsErrors))
    }
  }

  isCarblyBundleSelected = () => {
    const { selectedOfferings } = this.state
    return Boolean(selectedOfferings.find((offering) => offering.sku === CARBLY_PLUS_BUNDLE_SKU))
  }

  toggleOffering = (offering) => {
    const { offerings } = this.props
    var newSelectedOfferings = [...this.state.selectedOfferings]
    if (!newSelectedOfferings.find((item) => offering.sku === item.sku)) {
      newSelectedOfferings.push(offering)
    } else {
      newSelectedOfferings = newSelectedOfferings.filter((item) => item.sku !== offering.sku)
    }

    if (newSelectedOfferings.length > 0) {
      // If all offerings are carbly bundle, but bundle not selected, select the bundle
      if (!this.isCarblyBundleSelected()) {
        const accountDetails = this.props.accountDetails
        let allOfferingsAreCarblyBundle = true
        for (let sku of this.carblyPlusBundleOfferings) {
          if (!newSelectedOfferings.find((offering) => offering.sku === sku) && !accountDetails?.account_offerings.find((offering) => offering.sku === sku)) {
            allOfferingsAreCarblyBundle = false
            break
          }
        }

        if (allOfferingsAreCarblyBundle) {
          newSelectedOfferings.push(offerings.find((newOffering) => newOffering.sku === CARBLY_PLUS_BUNDLE_SKU))
        }
      }


      // If has carbly bundle, remove associated offerings
      if (Boolean(newSelectedOfferings.find((offering) => offering.sku === CARBLY_PLUS_BUNDLE_SKU))) {
        for (let sku of this.carblyPlusBundleOfferings) {
          newSelectedOfferings = newSelectedOfferings.filter((offering) => offering.sku !== sku)
        }
      }

      this.setState({
        selectedOfferings: newSelectedOfferings,
        isLoadingCostForSelected: true,
        costForSelected: null
      })

      if (this.props.onCostForSelectedOfferingsLoadingStateChange) {
        this.props.onCostForSelectedOfferingsLoadingStateChange(true)
      }

      UserActionCreators.loadPricingForOfferings(newSelectedOfferings.map((offering) => offering.sku), this.props.billingIntervalOverride)
    } else {
      this.setState({
        selectedOfferings: newSelectedOfferings,
        isLoadingCostForSelected: false,
        costForSelected: null
      })
      if (this.props.onSelectedOfferingsChange) {
        this.props.onSelectedOfferingsChange([], null)
      }
    }
    platformFunctions.logAnalyticsEvent("User", "Toggled Add Offering")
  }

  renderOfferings = (offerings, isBillingIntervalYearly) => {
    let out = []
    let wasCarblyProduct = true
    const hasPurchasedCarblyPlusBundle = SessionStore.hasPurchasedCarblyPlusBundle()
    let bundleSubsetMessages = []
    let alreadyPurchasedBundleOfferingSKUs = []
    if (!hasPurchasedCarblyPlusBundle) {
      for (let sku of this.carblyPlusBundleOfferings) {
        if (!offerings.find((offering) => offering.sku === sku)) {
          let offeringKey = Object.keys(AppConstants.skuMappings).find(key => AppConstants.skuMappings[key] === sku)
          // Hack because we have inconsistent naming keys for market tracker
          offeringKey = offeringKey === 'marketTracker' ? 'regional_report' : offeringKey
          bundleSubsetMessages.push(AppConstants.providerNames[offeringKey])
          alreadyPurchasedBundleOfferingSKUs.push(sku)
        }
        
      }
    }

    for (let i = 0; i < offerings.length; i++) {
      const offering = offerings[i]

      if (hasPurchasedCarblyPlusBundle && this.carblyPlusBundleOfferings.includes(offering.sku)) {
        continue
      }

      if (i === 0 && offering.sku === CARBLY_PLUS_BUNDLE_SKU) {
        out.push(<h2 key='features-title'>Carbly Features</h2>)
      }

      let style = {}
      if (i !== 0 && wasCarblyProduct && !this.carblyPlusBundleOfferings.includes(offering.sku)) {
        // Push in the already purchased bundle offerings
        for (let alreadyPurchasedBundleOfferingSKU of alreadyPurchasedBundleOfferingSKUs) {
          out.push(this.renderAddOffering({sku: alreadyPurchasedBundleOfferingSKU}, isBillingIntervalYearly, style))
        }
          
        if (bundleSubsetMessages.length > 0) {
          out.push(<div style={{marginLeft: '25px'}} key='existing-bundle-offerings' className='secondary-text'>Your account already includes {pluralize('item', bundleSubsetMessages.length, true)} from the bundle: {bundleSubsetMessages.join(', ')}</div>)
        }

        out.push(<h2 key='providers-title' style={{marginTop: '40px'}}>Data Providers</h2>)
        wasCarblyProduct = false
      }
      out.push(this.renderAddOffering(offering, isBillingIntervalYearly, style))
    }

    return (
      <div>{out}</div>
    )
  }

  renderAddOffering = (offering, isBillingIntervalYearly, style) => {
    const { selectedOfferings } = this.state
    const { offerings } = this.props
    const isCarblyBundleSelected = this.isCarblyBundleSelected()
    const isDisabledDueToCarblyBundle = isCarblyBundleSelected && this.carblyPlusBundleOfferings.includes(offering.sku)
    const isSelected = Boolean(selectedOfferings.find((selected) => selected.sku === offering.sku))
    const isCarblyBundleOffering = this.carblyPlusBundleOfferings.includes(offering.sku)

    // Determine how many bundled offerings have not been purchased, which dictates pricing treatment
    let unPurchasedBundledOfferingsCount = 0
    for (let bundledOfferingSKU of this.carblyPlusBundleOfferings) {
      if (offerings.find((offering) => offering.sku === bundledOfferingSKU)) {
        unPurchasedBundledOfferingsCount++
      }
    }

    let priceString = ''
    let bundledPrice = null
    let alreadyPurchasedBundleItem = false
    if (!(offerings.find(o => o.sku === offering.sku))) {
      priceString = 'Bundle item already purchased'
      alreadyPurchasedBundleItem = true
    } else {
      const price = offering.price[isBillingIntervalYearly ? 'year' : 'month']
      priceString = `${Formatters.formatCurrencyCents(price)} per ${isBillingIntervalYearly ? 'year' : 'month'}`
      if (offering.sku === CARBLY_PLUS_BUNDLE_SKU && unPurchasedBundledOfferingsCount === this.carblyPlusBundleOfferings.length) {
        // No bundled items have been purchased, so only show the full bundle price
        priceString = null
      }

      // If it's the last offering in a bundle, show the bundled price with the original price struck through
      if (isCarblyBundleOffering && unPurchasedBundledOfferingsCount === 1 || offering.sku === CARBLY_PLUS_BUNDLE_SKU) {
        const bundlePrice = offerings.find(o => o.sku === CARBLY_PLUS_BUNDLE_SKU).price?.incremental
        bundledPrice = `${Formatters.formatCurrencyCents(bundlePrice)} per ${isBillingIntervalYearly ? 'year' : 'month'}`
      } else {
        
      }
    }
    
    let providerKey = offering.provider_key
    if (!offering.provider_key && offering.sku === 'feature_market_tracker') {
      providerKey = 'market_tracker'
    } else if (!offering.provider_key && offering.sku === 'feature_auctions_plus') {
      providerKey = 'auctions_plus'
    } else if (!offering.provider_key && offering.sku === 'feature_lot_sense') {
      providerKey = 'lot_sense'
    } else if (!offering.provider_key && offering.sku === CARBLY_PLUS_BUNDLE_SKU) {
      providerKey = 'carbly_plus_bundle'
    }

    return (
      <a href="#toggle" className={`account-add-offering ${isSelected || alreadyPurchasedBundleItem ? 'account-add-offering-selected' : ''}`} style={{opacity: isDisabledDueToCarblyBundle || alreadyPurchasedBundleItem ? 0.4 : 1.0, marginLeft: isCarblyBundleOffering ? '20px' : 0, ...style}} onClick={(e) => {
          e.preventDefault()
          if (!isDisabledDueToCarblyBundle && !alreadyPurchasedBundleItem) {
            this.toggleOffering(offering)
          }
          }} key={`avail-off-${offering.sku}`}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div style={{width: '120px', marginRight: '20px'}}><ProviderLogo providerKey={providerKey} style={{marginRight: '20px', width: '100%', height: 'auto'}} /></div>

          <div>
            <h3>{offering.name}</h3>
            <div><span style={{textDecoration: bundledPrice ? 'line-through' : 'none'}}>{priceString}</span> {bundledPrice}</div>
          </div>
        </div>
        {
          !alreadyPurchasedBundleItem &&
          <CheckCircleIcon checked={isSelected || alreadyPurchasedBundleItem} className={isSelected ? 'selected' : ''} />
        }
      </a>
    )
  }

  addOfferings = () => {
    const { selectedOfferings, costForSelected } = this.state

    if (this.props.onAddOfferings) {
      this.props.onAddOfferings(selectedOfferings, costForSelected)
    } else {
      const needsPaymentMethod = SessionStore.needsPaymentMethod()

      if (needsPaymentMethod) {
        this.setState({
          isShowingAddPaymentModal: true
        })
      } else {
        this.setState({
          isAddingOfferings: true
        })
        UserActionCreators.addOfferings(selectedOfferings.map((offering) => offering.sku))
      }
    }
  }

  offeringsAdded = () => {
    var addOfferingErrors = null
    if (SessionStore.addOfferingsErrorMessage) {
      addOfferingErrors = SessionStore.addOfferingsErrorMessage.join(' ')
    }

    this.setState({
      isAddOfferingsComplete: true,
      isAddingOfferings: false,
      selectedOfferings: Boolean(addOfferingErrors) ? this.state.selectedOfferings : [],
      addOfferingErrors: addOfferingErrors
    })

    if (!addOfferingErrors) {
      toast.success("You plan was updated")
      setTimeout(() => {
        if (this.props.onOfferingsAdded) {
          this.props.onOfferingsAdded()
        }
      }, 2000)
    }
  }

  render() {
    const { offerings, accountDetails, useTotalPrice } = this.props
    const { isLoadingCostForSelected, costForSelected, selectedOfferings, isAddingOfferings, isAddOfferingsComplete, addOfferingErrors, pricingForOfferingsErrors } = this.state

    var nonAuxUsersCount = 0
    if (accountDetails?.user_details) {
      nonAuxUsersCount = accountDetails?.user_details.filter((item) => item.auxiliary === false).length
    }

    const user = SessionStore.user
    const isTrialing = SessionStore.isTrialing()
    const prorationAmount = costForSelected?.proration_amount

    var isBillingIntervalYearly = accountDetails?.billing_interval === 'year'
    if (this.props.billingIntervalOverride) {
      isBillingIntervalYearly = this.props.billingIntervalOverride === 'year'
    }

    var cost = 0
    if (useTotalPrice && costForSelected?.total_price) {
      cost = costForSelected.total_price
    } else if (costForSelected?.incremental_price) {
      cost = costForSelected.incremental_price
    }

    // Move Carbly stuff to the top
    const reorderedOfferings = CommonFunctions.reorderOfferingsForDisplay(offerings)

    return (
      <div>
        <div style={{position: 'relative'}}>
          { this.renderOfferings(reorderedOfferings, isBillingIntervalYearly) }
          {
            isAddingOfferings &&
            <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#f7f7fa', opacity: 0.8}} />
          }
        </div>

        {
          isAddOfferingsComplete && addOfferingErrors &&
          <div style={{color: 'red', textAlign: 'center', margin: '10px 0'}}>{addOfferingErrors}</div>
        }

        {
          isLoadingCostForSelected &&
          <div style={{minHeight: '180px'}}>
            <Spinner className='spinner-medium' />
          </div>
        }

        {
          !isLoadingCostForSelected && !pricingForOfferingsErrors &&
          <div style={{textAlign: 'right', fontSize: '1.2rem'}}>
            {
              !isAddingOfferings &&
              <div>
                <div style={{fontSize: '1.4rem'}}>{useTotalPrice ? 'Total' : 'Additional'} {isBillingIntervalYearly ? 'yearly' : 'monthly'} cost: <span style={{fontWeight: 'bold'}}>{Formatters.formatCurrencyCents(cost)}</span></div>
                <div className='secondary-text'>Sales tax may apply</div>
                {
                  Boolean(costForSelected?.pricing_message) &&
                  <div className="secondary-text">{costForSelected.pricing_message}</div>
                }

                {
                  // Not used currently, future proofing for any discount messages
                  Boolean(costForSelected?.pricing_message) &&
                  <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px'}}>{costForSelected.pricing_message}</div>
                }
                {
                  nonAuxUsersCount > 1 &&
                  <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px'}}>Price includes the cost for {nonAuxUsersCount} users</div>
                }
                {
                  isTrialing &&
                  <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px'}}>You will not be charged until your free trial ends</div>
                }
                {
                  !isTrialing && costForSelected && !prorationAmount &&
                  <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px'}}>Your next bill will include a prorated charge</div>
                }
                {
                  !isTrialing && prorationAmount &&
                  <div className="secondary-text" style={{textAlign: 'right', marginTop: '5px', color: 'red', fontWeight: 'bold'}}>
                  You will be immediately charged {Formatters.formatCurrencyCents(prorationAmount)} for the prorated amount of your current billing cycle</div>
                }

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                  <Button
                    onClick={this.addOfferings}
                    color='primary'
                    style={{marginTop: '20px', marginBottom: '20px', ...this.props.submitStyle}}
                    disabled={isAddingOfferings || isAddOfferingsComplete || selectedOfferings.length === 0}>
                      { this.props.submitTitle || 'Update Account'}
                    </Button>
                </div>
              </div>
            }

            {
              isAddingOfferings &&
              <Spinner className='spinner-medium'/>
            }

            {
              this.props.onCancel &&
              <div style={{textAlign: 'center'}}>
                <a href="#cancel" onClick={(e) => {
                  e.preventDefault()
                  this.props.onCancel()}}>Cancel</a>
              </div>
            }

          </div>
        }

        {
          Boolean(pricingForOfferingsErrors) &&
          <div style={{margin: '20px 0', color: 'red', textAlign: 'center'}}>{pricingForOfferingsErrors.join('. ')}</div>
        }

        {
          this.state.isShowingAddPaymentModal &&
          <PaymentFormModal isAccountOwner={true} onCancel={() => this.setState({ isShowingAddPaymentModal: false})} title='Add a Payment Method' />
        }
      </div>
    )
  }

}
