import { useMemo, useState, useCallback, useRef } from 'react'
import pluralize from 'pluralize'
import moment from 'moment'
import Formatters from '../../api/constants/Formatters'
import LineChart from '../myLot/LineChart'
import maxBy from 'lodash/maxBy'
import CurrencyInputField from '../common/CurrencyInputField'
import NumberInputField from '../common/NumberInputField'
import RcSlider from 'rc-slider'
import platformFunctions from '../../platformFunctions'
import { debounce } from 'lodash'
import { Tooltip } from 'reactstrap'
import InfoIcon from '../common/icons/InfoIcon'
import AppConstants from '../../api/constants/AppConstants'
import RetailListingsModal from '../myLot/RetailListingsModal'
import UserActionCreators from '../../api/actions/UserActionCreators'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import SessionStore from '../../api/stores/SessionStore'

const Alert = ({ text }) => {
  const ref = useRef()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const element = <InfoIcon width={14} height={14} color='rgba(0,0,0,0.4)' />

  return (
    <>
      <div ref={ref} className='strategy-widget-alert'>
        {element}
      </div>
      <Tooltip
        isOpen={tooltipIsOpen}
        target={ref}
        toggle={() => setTooltipIsOpen(!tooltipIsOpen)}>
        {text}
      </Tooltip>
    </>
  )
}

const inputDebounceMs = 800

const interpolate = (p1, p2, portion, attr) => p1[attr] + portion * (p2[attr] - p1[attr])
const formatCurrency = value => value == null ? null : Formatters.formatCurrency(Math.round(value))

const Slider = ({ value, step, onChange, range, marksFormatter }) => {
  return (
    <div>
      <div style={{ marginRight: 28, marginLeft: 28, marginBottom: 20 }}>
        <RcSlider
          step={step}
          allowCross={false}
          min={range[0]}
          max={range[1]}
          marks={range.reduce((acc, v) => ({ ...acc, [v]: marksFormatter ? marksFormatter(v) : v }), {})}
          range={false}
          value={value}
          onChange={onChange}
          {...platformFunctions.sliderStyleProps()}
        />
      </div>
    </div>
  )
}

const StrategyWidget = ({ valuation, className, showTitle = true, useManualACV = false, clientID }) => {
  const {
    forecast: {
      prices_forecast,
      prices_dol,
      region_listings,
    },
    lot_vehicle: lotVehicle,
    vin,
    year,
    make,
    model,
    series: trim,
    zip_code: zip,
  } = valuation

  const [isShowingRegionalListingsModal, setIsShowingRegionalListingsModal] = useState(false)
  const lotVehicleInitialListPrice = lotVehicle?.first_price_cents != null ? lotVehicle.first_price_cents / 100 : null
  const lotVehicleCurrentListPrice = lotVehicle?.price

  const retailListingsModalFilter = useCallback(item => {
    return item.vin !== vin
  }, [vin])

  const optimumData = useMemo(() => {
    const d = maxBy(prices_dol, 'final_price') || {}
    return {
      daysOnLot: d.days_on_lot,
      initialListPrice: d.price,
      finalListPrice: d.final_price,
      portionOfMarket: d.portion_of_market,
      priceDrop: d.price_drop,
      firstPriceChangeDays: d.first_price_change_days,
    }
  }, [prices_dol])

  const optimizerRanges = useMemo(() => {
    const prices = prices_dol.map(v => v.price)
    const dol = prices_dol.map(v => v.days_on_lot)
    const portionOfMarket = prices_dol.map(v => v.portion_of_market)
    const priceDrop = prices_dol.map(v => v.price_drop)
    const firstPriceChangeDays = prices_dol.map(v => v.first_price_change_days)
    return {
      price: [Math.min(...prices), Math.max(...prices)],
      daysOnLot: [Math.min(...dol), Math.max(...dol)],
      portionOfMarket: [Math.min(...portionOfMarket), Math.max(...portionOfMarket)],
      priceDrop: [Math.min(...priceDrop), Math.max(...priceDrop)],
      firstPriceChangeDays: [Math.min(...firstPriceChangeDays), Math.max(...firstPriceChangeDays)],
    }
  }, [prices_dol])

  const getOptimizerIntersectionDays = useCallback(daysToTurn => {
    const data = prices_dol
    let initialListPrice, finalListPrice, portionOfMarket, priceDrop, firstPriceChangeDays, idx, point1, point2

    if (daysToTurn != null) {
      if (daysToTurn === data[0].days_on_lot) {
        idx = 1
      } else {
        idx = data.findIndex(v => v.days_on_lot >= daysToTurn)
      }
    }

    if (idx > 0) {
      point1 = data[idx - 1]
      point2 = data[idx]
    }

    if (point1 && point2) {
      const diff = point2.days_on_lot - point1.days_on_lot
      const portion = (daysToTurn - point1.days_on_lot) / diff
      initialListPrice = interpolate(point1, point2, portion, 'price')
      finalListPrice = interpolate(point1, point2, portion, 'final_price')
      portionOfMarket = interpolate(point1, point2, portion, 'portion_of_market')
      priceDrop = interpolate(point1, point2, portion, 'price_drop')
      firstPriceChangeDays = interpolate(point1, point2, portion, 'first_price_change_days')
    }

    return {
      initialListPrice,
      finalListPrice,
      daysOnLot: daysToTurn,
      portionOfMarket,
      priceDrop,
      firstPriceChangeDays,
    }
  }, [prices_dol])

  const getOptimizerIntersectionPrice = useCallback((listPrice) => {
    const data = prices_dol
    let finalListPrice, daysOnLot, portionOfMarket, priceDrop, firstPriceChangeDays, idx, point1, point2

    if (listPrice != null) {
      if (listPrice === data[0]?.price) {
        idx = 1
      } else {
        idx = data.findIndex(v => v.price >= listPrice)
      }
    }

    if (idx > 0) {
      point1 = data[idx - 1]
      point2 = data[idx]
    }

    if (point1 && point2) {
      const diff = point2.price - point1.price
      const portion = (listPrice - point1.price) / diff
      daysOnLot = interpolate(point1, point2, portion, 'days_on_lot')
      finalListPrice = interpolate(point1, point2, portion, 'final_price')
      portionOfMarket = interpolate(point1, point2, portion, 'portion_of_market')
      priceDrop = interpolate(point1, point2, portion, 'price_drop')
      firstPriceChangeDays = interpolate(point1, point2, portion, 'first_price_change_days')
    }

    return {
      initialListPrice: listPrice,
      finalListPrice,
      daysOnLot,
      portionOfMarket,
      priceDrop,
      firstPriceChangeDays,
    }
  }, [prices_dol])

  const [turnOptimizerData, setTurnOptimizerData] = useState(() => {
    if (lotVehicleCurrentListPrice != null) {
      return getOptimizerIntersectionPrice(lotVehicleCurrentListPrice)
    } else {
      return optimumData
    }
  })

  const [manualACVInput, setManualACVInput] = useState(null)
  const [listPriceManuallyChanged, setListPriceManuallyChanged] = useState(false)
  const [turnManuallyChanged, setTurnManuallyChanged] = useState(false)
  const listPrice  = turnOptimizerData.initialListPrice
  const daysToTurn = turnOptimizerData.daysOnLot

  const priceRanking = useMemo(() => {
    if (listPrice == null) return null

    // Exclude current vehicle
    // Add current list price
    // Sort by price desc, nulls last (reverse of what we want, but moves price equality to higher rank)
    // Rank is then inverse index of current list price (population.length - index)

    const population = region_listings
      .filter(v => v.vin !== vin)
      .reduce((acc, v) => ([...acc, v.price ]), [listPrice])
      .sort((a, b) => (a === null) - (b === null) || (b - a))

    if (population.length === 0) return null

    const index = population.indexOf(listPrice)

    return {
      total: population.length,
      rank: population.length - index,
    }
  }, [region_listings, listPrice, vin])

  const pricesForecastData = useMemo(() => {
    // assumes sorted by date asc
    return prices_forecast.map(v => {
      const result = v
      if (listPrice == null) {
        result.asking_forecast = null
      } else {
        const firstPriceDol = prices_forecast[0].retail_dol_forecast
        result.asking_forecast = listPrice - (firstPriceDol - v.retail_dol_forecast)
      }
      return result
    })
  }, [prices_forecast, lotVehicleCurrentListPrice, listPrice])

  const percentOfMarketMessage = useMemo(() => {
    if (listPrice > optimizerRanges.price[1]) {
      return `> ${Math.round(optimizerRanges.portionOfMarket[1] * 100)}% of market`
    } else if (listPrice < optimizerRanges.price[0]) {
      return `< ${Math.round(optimizerRanges.portionOfMarket[0] * 100)}% of market`
    } else if (turnOptimizerData.portionOfMarket == null){
      // Hold vertical space
      return "\u00A0"
    } else {
      return `${Formatters.formatNumber(turnOptimizerData.portionOfMarket * 100, {maximumFractionDigits: 1})}% of market`
    }
  }, [turnOptimizerData, optimizerRanges, listPrice])

  const onListPriceChange = useCallback(value => {
    if (!listPriceManuallyChanged) {
      UserActionCreators.createMetric(AppConstants.metricNames['my_lot_strategy_change'], 'list_price', { uuid: valuation.uuid })
      setListPriceManuallyChanged(true)
    }

    setTurnOptimizerData(getOptimizerIntersectionPrice(value))
  }, [getOptimizerIntersectionPrice, listPriceManuallyChanged])

  const debouncedOnListPriceChange = useCallback(debounce(onListPriceChange, inputDebounceMs), [onListPriceChange])

  const onListPriceInputChange = useCallback((value, _mask, inputEvent) => {
    if (inputEvent) debouncedOnListPriceChange(value.length ? parseInt(value) : null)
  }, [debouncedOnListPriceChange])

  const onListPriceSliderChange = useCallback(value => {
    onListPriceChange(value)
  }, [onListPriceChange])

  const onDaysToTurnChange = useCallback(value => {
    if (!turnManuallyChanged) {
      UserActionCreators.createMetric(AppConstants.metricNames['my_lot_strategy_change'], 'days_to_run', { uuid: valuation.uuid })
      setTurnManuallyChanged(true)
    }
    setTurnOptimizerData(getOptimizerIntersectionDays(value))
  }, [getOptimizerIntersectionDays, turnManuallyChanged])

  const debouncedOnDaysToTurnChange = useCallback(debounce(onDaysToTurnChange, inputDebounceMs), [onDaysToTurnChange])

  const onDaysToTurnInputChange = useCallback((value, _mask, inputEvent) => {
    if (inputEvent) debouncedOnDaysToTurnChange(value.length ? parseInt(value) : null)
  }, [debouncedOnDaysToTurnChange])

  const onDaysToTurnSliderChange = useCallback(value => {
    onDaysToTurnChange(value)
  }, [onDaysToTurnChange])

  const numberInputValue = useCallback(value => {
    return value == null ? null : Math.round(value)
  }, [])

  const onShowOptimumTurnOptimizerStrategyClick = useCallback(() => {
    UserActionCreators.createMetric(AppConstants.metricNames['my_lot_strategy_preset'], 'best', { uuid: valuation.uuid })
    setTurnOptimizerData(optimumData)
  }, [optimumData])

  const onShowListPriceTurnOptimizerStrategyClick = useCallback(() => {
    UserActionCreators.createMetric(AppConstants.metricNames['my_lot_strategy_preset'], 'list', { uuid: valuation.uuid })
    setTurnOptimizerData(optimumData)
    setTurnOptimizerData(getOptimizerIntersectionPrice(lotVehicleCurrentListPrice))
  }, [lotVehicleCurrentListPrice, getOptimizerIntersectionPrice])

  const isTurnOptimizerShowingOptimum = turnOptimizerData.initialListPrice === optimumData.initialListPrice
  const isTurnOptimizerShowingListPrice = lotVehicleCurrentListPrice != null && turnOptimizerData.initialListPrice === lotVehicleCurrentListPrice

  const turnOptimizerChartProps = useMemo(() => {
    const data = [...prices_dol]

    const currentData = Object.values(turnOptimizerData).filter(v => v !== undefined).length < 2 ? [] : [
      {
        daysOnLot: turnOptimizerData.daysOnLot,
        price: turnOptimizerData.initialListPrice,
        finalListPrice: turnOptimizerData.finalListPrice,
        initialListPrice: turnOptimizerData.initialListPrice,
        priceDrop: turnOptimizerData.priceDrop,
        portionOfMarket: turnOptimizerData.portionOfMarket,
        firstPriceChangeDays: turnOptimizerData.firstPriceChangeDays,
      },
      {
        daysOnLot: turnOptimizerData.daysOnLot,
        price: turnOptimizerData.finalListPrice,
        finalListPrice: turnOptimizerData.finalListPrice,
        initialListPrice: turnOptimizerData.initialListPrice,
        priceDrop: turnOptimizerData.priceDrop,
        portionOfMarket: turnOptimizerData.portionOfMarket,
        firstPriceChangeDays: turnOptimizerData.firstPriceChangeDays,
      },
    ]

    const colors = {
      finalPrice: '#02b1fc',
      price: 'rgb(234, 66, 54)',
      current: 'rgb(42, 167, 42)',
    }

    const interaction = {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    }

    return {
      options: {
        scales: {
          x: {
            type: 'linear',
            ticks: {
              stepSize: 5,
            },
          },
          y: {
            display: true,
            type: 'linear',
            ticks: {
              callback: Formatters.formatCurrency,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            interaction,
            filter: ctx => {
              return ctx.datasetIndex === 0 || ctx.datasetIndex === 2 && ctx.dataIndex === 1
            },
            callbacks: {
              title: () => null,
              label: ctx => {
                const { chart, datasetIndex } = ctx
                const { dataPoints } = chart.tooltip
                const index = dataPoints.indexOf(ctx)
                let lines = []

                if (datasetIndex === 0) {
                  lines = [
                    `Days on lot: ${ctx.raw.days_on_lot}`,
                    `Days until first price drop: ${Math.round(ctx.raw.first_price_change_days)}`,
                    `Initial list price: ${formatCurrency(ctx.raw.price)}`,
                    `Expected price drop: ${formatCurrency(ctx.raw.price_drop)}`,
                    `Final list price: ${formatCurrency(ctx.raw.final_price)}`,
                    `Percent of market: ${Formatters.formatNumber(ctx.raw.portion_of_market * 100, {maximumFractionDigits: 1})}%`,
                  ]
                }

                if (datasetIndex === 2) {
                  lines = [
                    `Selected days on lot: ${Math.round(ctx.raw.daysOnLot)}`,
                    `Selected days until first price drop: ${Math.round(ctx.raw.firstPriceChangeDays)}`,
                    `Selected list price: ${formatCurrency(ctx.raw.initialListPrice)}`,
                    `Selected expected price drop: ${formatCurrency(ctx.raw.priceDrop)}`,
                    `Selected final list price: ${formatCurrency(ctx.raw.finalListPrice)}`,
                    `Selected percent of market: ${Formatters.formatNumber(ctx.raw.portionOfMarket * 100, {maximumFractionDigits: 1})}%`,
                  ]
                }

                if (index < dataPoints.length - 1) {
                  lines.push('') // space between items
                }

                return lines
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
        interaction,
        elements: {
          line: {
            borderWidth: 3,
          },
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      data: {
        datasets: [
          {
            fill: false,
            data,
            parsing: {
              xAxisKey: 'days_on_lot',
              yAxisKey: 'price',
            },
            label: 'Initial Price',
            borderColor: colors.price,
            backgroundColor: colors.price,
            hoverBackgroundColor: colors.price,
          },
          {
            fill: false,
            data,
            parsing: {
              xAxisKey: 'days_on_lot',
              yAxisKey: 'final_price',
            },
            label: 'Final Price',
            borderColor: colors.finalPrice,
            backgroundColor: colors.finalPrice,
            hoverBackgroundColor: colors.finalPrice,
          },
          {
            fill: false,
            data: currentData,
            parsing: {
              xAxisKey: 'daysOnLot',
              yAxisKey: 'price',
            },
            label: 'Current',
            borderColor: colors.current,
            backgroundColor: colors.current,
            pointRadius: 6,
            pointStyle: 'line',
            pointBorderWidth: 2,
            hoverBackgroundColor: colors.current,
            pointHoverBorderColor: colors.current,
            pointHoverRadius: 6,
            pointHoverBorderWidth: 2,
          },
        ],
      },
    }
  }, [prices_dol, lotVehicle, turnOptimizerData])

  const unitPerformanceForecastChartProps = useMemo(() => {
    const yFormatFunction = Formatters.formatCurrency
    const data = [...pricesForecastData]
    const firstForecastDate = data[0]?.date

    const datasets = {
      acv: data.map(v => (useManualACV ? manualACVInput : v.acv)),
      listPrice: data.map(v => v.list_price),
      currentPrice: data.map(v => v.asking_forecast),
      retailForecast: data.map(v => v.retail_forecast),
      wholesaleForecast: data.map(v => v.wholesale_forecast),
    }

    const colors = {
      retailForecast: '#02b1fc',
      wholesaleForecast: '#505d62',
      askingForecast: 'rgb(255, 109, 1)',
      acv: 'rgb(206, 200, 32)',
      listPrice: 'rgb(52, 167, 84)',
    }

    const dataDatasets = [
      {
        label: 'Retail Forecast',
        data: datasets.retailForecast,
        borderColor: colors.retailForecast,
        backgroundColor: colors.retailForecast,
        hoverBackgroundColor: colors.retailForecast,
        fill: false,
      },
      {
        label: 'Wholesale Forecast',
        data: datasets.wholesaleForecast,
        borderColor: colors.wholesaleForecast,
        backgroundColor: colors.wholesaleForecast,
        hoverBackgroundColor: colors.wholesaleForecast,
        fill: false,
      },
      {
        label: 'ACV',
        data: datasets.acv,
        borderColor: colors.acv,
        backgroundColor: colors.acv,
        hoverBackgroundColor: colors.acv,
        fill: false,
        borderDash: [5, 5],
        pointRadius: 0,
        pointStyle: 'line',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 0,
      },
      {
        label: 'Current Price',
        borderDash: [5, 5],
        data: datasets.listPrice,
        borderColor: colors.listPrice,
        backgroundColor: colors.listPrice,
        hoverBackgroundColor: colors.listPrice,
        fill: false,
        borderDash: [5, 5],
        pointRadius: 0,
        pointStyle: 'line',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 0,
      },
    ]

    // Forecast asking price line
    const hasAskingForecast = data.some(v => v.asking_forecast !== null)

    if (hasAskingForecast) {
      datasets.askingForecast = pricesForecastData.map(v => v.asking_forecast)
      dataDatasets.splice(2, 0, {
        label: 'Aging Unit Reduction',
        data: datasets.askingForecast,
        borderColor: colors.askingForecast,
        backgroundColor: colors.askingForecast,
        hoverBackgroundColor: colors.askingForecast,
        fill: false,
      })
    }

    const allData = Object.values(datasets).flat()

    return {
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: true,
          },
          tooltip: {
            displayColors: true,
            callbacks: {
              title: ctx => {
                const date = moment(data[ctx[0].dataIndex].date).startOf('day')
                const daysDiff = date.diff(firstForecastDate, 'days') + 1
                return `${pluralize('day', daysDiff, true)} from now`
              },
              label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
            },
          },
        },
        scales: {
          y: {
            display: true,
            suggestedMin: Math.min(...allData.filter(v => v != null)) * 0.97,
            ticks: {
              callback: yFormatFunction,
            },
          },
        },
        elements: {
          line: {
            borderWidth: 3,
          },
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      data: {
        labels: firstForecastDate ? data.map(d => moment(d.date).diff(firstForecastDate, 'days') + (lotVehicle ? lotVehicle.days_on_lot : 0) + 1) : [],
        datasets: dataDatasets,
      },
      yFormatFunction,
    }
  }, [pricesForecastData, lotVehicle, useManualACV, manualACVInput])

  const unitPerformanceCrosses = useMemo(() => {
    const agedAskingBelowRetailIndex = pricesForecastData.findIndex(v => v.asking_forecast <= v.retail_forecast)
    const agedRetailBelowAcvIndex = pricesForecastData.findIndex(v => v.asking_forecast <= (useManualACV ? manualACVInput : v.acv))
    const wholesaleAboveAcvIndex = pricesForecastData.findIndex(v => v.wholesale_forecast >= (useManualACV ? manualACVInput : pricesForecastData[0].acv))

    const agedAskingBelowRetail = agedAskingBelowRetailIndex > 0 ? pricesForecastData[agedAskingBelowRetailIndex] : null
    const agedRetailBelowAcv = agedRetailBelowAcvIndex > 0 ? pricesForecastData[agedRetailBelowAcvIndex] : null
    const wholesaleAboveAcv = wholesaleAboveAcvIndex === 0 ? pricesForecastData[0] : null

    return {
      agedAskingBelowRetail,
      agedRetailBelowAcv,
      wholesaleAboveAcv,
    }
  }, [pricesForecastData, useManualACV, manualACVInput])

  const getDaysFromNow = useCallback(dateStr => {
    const date = moment(dateStr).startOf('day')
    const daysDiff = date.diff(pricesForecastData[0]?.date, 'days') + 1
    return `${pluralize('day', daysDiff, true)}`
  }, [pricesForecastData])


  let listPriceRenderItems = []

  if (listPrice !== null) {
    if (listPrice > optimizerRanges.price[1]) {
      listPriceRenderItems.push(
        <div key='expected'><div className='label'>Expect price reduction of at least</div> <div className='value'>{formatCurrency(optimizerRanges.priceDrop[1])}</div></div>
      )
    } else if (listPrice < optimizerRanges.price[0]) {
      listPriceRenderItems.push(<div key='no-reduction'><div className='label'>No price reduction expected</div></div>)
    } else {
      listPriceRenderItems.push(
        <div key='expected-sale'><div className='label'>Expected sale price</div> <div className='value'>{formatCurrency(turnOptimizerData.finalListPrice)}</div></div>
      )
      listPriceRenderItems.push(
        <div key='typical'><div className='label'>Typical price reduction</div> <div className='value'>{formatCurrency(turnOptimizerData.priceDrop)}</div></div>
      )
    }
  }

  const derivedACV = useManualACV ? manualACVInput : lotVehicle?.acv

  return (
    <>
      <div style={{marginTop: '40px'}} className={className}>
        {
          showTitle &&
          <div style={{fontWeight: 'bold', fontSize: '1.5rem'}}>{AppConstants.providerNames['turn_optimizer']}</div>
        }
        <div className='strategy-widget-section' style={{width: '100%'}}>
          <div className='strategy-widget-controls-c'>
            <div style={{width: '50%'}} className='strategy-widget-list-price-c'>
              <div>
                <div style={{fontWeight: 'bold'}}>List Price

                {
                  !isTurnOptimizerShowingListPrice && listPrice &&
                  <button 
                    className='text-button' 
                    style={{lineHeight: '1rem', marginLeft: '10px'}}
                    onClick={() => {
                      if ((SessionStore.user?.rooftop?.lot_inventory_status === 'read_write' && confirm('You have price changes enabled, so any change you make to the listed price here will be published back through your system of record.')) || SessionStore.user?.rooftop?.lot_inventory_status !== 'read_write') {
                        ValuationActionCreators.updateLotVehicle({uuid: lotVehicle.uuid, price_cents: listPrice * 100}, clientID)
                      }
                    }}
                  >
                    Set vehicle to this list price
                  </button>
                }

                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                  <div style={{flexGrow: 1, flexShrink: 1, paddingTop: '14px'}}>
                    <Slider
                      range={optimizerRanges.price}
                      value={numberInputValue(listPrice)}
                      step={1}
                      marksFormatter={Formatters.formatCurrency}
                      onChange={onListPriceSliderChange}
                    />
                  </div>
                  <div style={{flexGrow: 0, flexShrink: 0, width: '140px'}} className='strategy-widget-price-input-c'>
                    <CurrencyInputField unmask value={numberInputValue(listPrice)} onChange={onListPriceInputChange} style={{ margin: 0 }} />
                    <div className='secondary-text' style={{ textAlign: 'center' }}>{percentOfMarketMessage}</div>
                  </div>
                </div>

                <div>

                </div>
              </div>
            </div>


            <div style={{width: '50%'}} className='strategy-widget-turn-c'>
              <div>
                <div style={{fontWeight: 'bold', paddingLeft: '22px'}}>Days to turn</div>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                  <div style={{flexGrow: 1, flexShrink: 1, paddingTop: '14px'}}>
                    <Slider
                      range={optimizerRanges.daysOnLot}
                      value={numberInputValue(daysToTurn)}
                      step={1}
                      onChange={onDaysToTurnSliderChange}
                    />
                  </div>
                  <div style={{flexGrow: 0, flexShrink: 0, width: '130px'}} className='strategy-widget-days-input-c'>
                    <NumberInputField unmask value={numberInputValue(daysToTurn)} onChange={onDaysToTurnInputChange} style={{ margin: 0 }} />
                  </div>
                </div>

              </div>
            </div>

            <div className='strategy-widget-presets-c'>
              <button className='link strategy-widget-preset-button' style={{opacity: isTurnOptimizerShowingOptimum ? '0.5' : '1.0'}} disabled={isTurnOptimizerShowingOptimum} onClick={onShowOptimumTurnOptimizerStrategyClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
                </svg>
                Select best
              </button>
              {
                lotVehicleCurrentListPrice !== null &&
                <>
                  <button className='link strategy-widget-preset-button' style={{opacity: isTurnOptimizerShowingListPrice ? '0.5' : '1.0'}} disabled={isTurnOptimizerShowingListPrice} onClick={onShowListPriceTurnOptimizerStrategyClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
                    </svg>
                    Select current
                  </button>
                </>
              }
            </div>

          </div>

          {
            useManualACV &&
            <div style={{marginLeft: '8px', marginTop: '10px', display: 'flex', flexDirection: 'row', columnGap: '10px', alignItems: 'center'}}>
              <div style={{fontWeight: 'bold'}}>ACV</div>
              <CurrencyInputField unmask value={numberInputValue(manualACVInput)} onChange={(value) => {
                const v = value.length ? parseInt(value) : null
                setManualACVInput(v)
                }} style={{ margin: 0, width: '110px' }} />
            </div>

          }

          {
            priceRanking !== null &&
            <div style={{ display:'flex', gap: 10, marginTop: 10, padding: '5px 8px', fontSize: '1.2rem', alignItems: 'center', justifyContent: 'center', fontSize: '0.95rem'}}>
              <div>List price vs local comps: {Formatters.formatNumber(priceRanking.rank)} / {Formatters.formatNumber(priceRanking.total)}</div>
              {
                priceRanking.total > 2 && priceRanking.total < 180 &&
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {
                    [...Array(priceRanking.total)].map((el, index) => {
                      return (
                        <div key={index} style={{width: '2px', height: '12px', backgroundColor: priceRanking.rank === index + 1 ? '#333' : '#bbb', marginRight: '3px'}} />
                      )
                    })
                  }
                </div>
              }
              <div>
                <button className='link' onClick={() => {
                  UserActionCreators.createMetric(AppConstants.metricNames['my_lot_strategy_show_comps'])
                  setIsShowingRegionalListingsModal(true)}
                  }>View comps</button>
              </div>
            </div>
          }

          <div className='strategy-results-c'>

           { listPriceRenderItems }

           {
              !useManualACV && derivedACV &&
                <div><div className='label'>ACV { !lotVehicle.costs && <Alert text='Note that ACV shown may be low as no costs have been entered for this unit.' /> }</div> <div className='value'>{formatCurrency(derivedACV)}</div></div>
            }

            {
              derivedACV && turnOptimizerData.finalListPrice && turnOptimizerData.initialListPrice &&
              <div><div className='label'>Profit potential</div> <div className='value'>{formatCurrency(turnOptimizerData.finalListPrice - derivedACV)}</div></div>
            }

            <div><div className='label'>Initial list price</div> <div className='value'>{lotVehicleInitialListPrice ? formatCurrency(lotVehicleInitialListPrice) : 'N/A'}</div></div>


            <div><div className='label'>Market potential expires { unitPerformanceCrosses.wholesaleAboveAcv && unitPerformanceCrosses.agedAskingBelowRetail && <Alert text='You may be able to wholesale out of this car without losing money.' /> }</div> <div className='value'>{
                (function() {
                  if (unitPerformanceCrosses.agedAskingBelowRetail) {
                    return getDaysFromNow(unitPerformanceCrosses.agedAskingBelowRetail.date)
                  } else {
                    return 'N/A'
                  }
                })()
              }
              </div>
            </div>


            <div><div className='label'>Profit reaches zero { !lotVehicle?.costs && unitPerformanceCrosses.agedRetailBelowAcv && <Alert text='Note that ACV shown may be low as no costs have been entered for this unit.' /> }</div> <div className='value'> {
                (function() {
                  if (unitPerformanceCrosses.agedRetailBelowAcv) {
                    return getDaysFromNow(unitPerformanceCrosses.agedRetailBelowAcv.date)
                  } else {
                    return 'N/A'
                  }
                })()
              }
              </div>
            </div>


            {
              (function() {
                if (listPrice > optimizerRanges.price[1]) {
                  return <div><div className='label'>Suggest 1st price drop</div> <div className='value'>{pluralize('day', Math.round(optimizerRanges.firstPriceChangeDays[1]), true)}</div></div>
                } else if (!(listPrice < optimizerRanges.price[0])) {
                  return <div><div className='label'>Suggest 1st price drop</div> <div className='value'>{pluralize('day', Math.round(turnOptimizerData.firstPriceChangeDays), true)}</div></div>
                }
              })()
            }


          </div>


          <div style={{marginTop: '20px', backgroundColor: '#ebf2ea', borderRadius: '6px', padding: '5px 8px', opacity: isTurnOptimizerShowingOptimum ? 0.5 : 1.0}}>
            <div><strong>Best Strategy:</strong> List price {formatCurrency(optimumData.initialListPrice)} ({`${Formatters.formatNumber(optimumData.portionOfMarket * 100, {maximumFractionDigits: 1})}%`} of market)</div>
            <div className='strategy-best-results-c'>
              <div>Days to turn {optimumData.daysOnLot}</div>
              <div>Expected sale price {formatCurrency(optimumData.finalListPrice)}</div>
              <div>Expected price drop {formatCurrency(optimumData.priceDrop)}</div>
              <div>Suggest 1st price drop {pluralize('day', Math.round(optimumData.firstPriceChangeDays), true)}</div>
            </div>
          </div>
        </div>


        <div className='strategy-results-charts-c'>
          <div className='strategy-widget-section' style={{width: '50%', height: '320px', marginBottom: 0, marginRight: '5px'}}>
            <div className='strategy-results-chart-title'>Pricing Strategy Outcomes</div>
            <div style={{height: '270px'}}>
              <LineChart {...turnOptimizerChartProps} />
            </div>
          </div>

          <div className='strategy-widget-section' style={{width: '50%', height: '320px', marginBottom: 0, marginLeft: '5px'}}>
            <div className='strategy-results-chart-title'>Unit Market Forecast</div>
            <div style={{height: '270px'}}>
              <LineChart {...unitPerformanceForecastChartProps} />
            </div>
          </div>
        </div>
      </div>
      {
        isShowingRegionalListingsModal &&
        <RetailListingsModal
          onClose={() => setIsShowingRegionalListingsModal(false)}
          year={year}
          make={make}
          model={model}
          trim={trim}
          zip={zip}
          filter={retailListingsModalFilter}
          defaultSort='price'
        />
      }
    </>
  )
}

export default StrategyWidget
